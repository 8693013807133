<template>
  <div class="container-fluid">
    <div class="row ">
      <div class="col-md-3">
        <div class="row d-flex flex-column">
          <div class="col-md-2 box m2"></div>
          <div class="col-md-2 box m2"></div>
          <div class="col-md-2 box m2"></div>
          <div class="col-md-2 box m2"></div>
          <div class="col-md-2 box m2"></div>
          <div class="col-md-2 box m2"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-9">
      <div class="row">
        <div class="col-md-2 box-2 "></div>
        <div class="col-md-2 box-2 "></div>
        <div class="col-md-2 box-2 "></div>
        <div class="col-md-2 box-2 "></div>
        <div class="col-md-2 box-2 "></div>
      </div>
    </div>
  </div>


    <div class="row">
      <div class="col-md-6"></div>
      <div class="col-md-6"></div>
    </div>

</template>

<script>
export default {

}
</script>

<style scoped>
.box{
  height: 40px;
  width:50px;
  background: red;
  margin: 2px;
}
.box-2{
  height: 40px;
  width:50px;
  background: red;
  margin: 2px;
}
</style>