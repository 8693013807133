<template>
  <div class="login-page">
    <div class="login-container">
      <h2>Parent Login</h2>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="username">Username:</label>
          <input type="text" id="username" v-model="username" />
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="password" />
        </div>
        <button type="submit">Login</button>
      </form>
      <p v-if="error" class="error">Invalid username or password</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      error: false
    }
  },
  methods: {
    login() {
    //   // Call API to authenticate parent
    //   axios.post('/api/parent/login', {
    //     username: this.username,
    //     password: this.password
    //   })
    //       .then(response => {
    //         // Handle successful login response
    //         // Save token or user data in local storage
    //         localStorage.setItem('parentToken', response.data.token)
    //         this.$router.push({ name: 'parent-dashboard' })
    //       })
    //       .catch(error => {
    //         // Handle login error
    //         this.error = true
    //       })
      this.$router.push({  name:'ParentsDashboard'})
     }
  }
}
</script>

<style scoped>
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
margin: 15px 0;
  background-color: #f5f5f5;
}

.login-container {
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"], input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
}

button[type="submit"] {
  background-color: #4CAF50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.error {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
</style>
