<template>


  <div class="row my-4">
    <div class="col-md-12 ">
      <div class="row  d-flex justify-content-center align-content-center">
        <div class="col-md-11 about-us">
          <h1 class="display-6">FEE STRUCTURE</h1>
          <h3>Current fees are as follows</h3>
          <h1></h1>
          <table class="table table-bordered  ">
            <thead>
            <tr>
              <th class="justify-content-center align-content-center">Amount (USD)</th>
              <th class="justify-content-center align-content-center">Description</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item, index) in feesStructure" :key="index">
              <td class="justify-content-center align-content-center">{{ item.amount }}</td>
              <td class="justify-content-center align-content-center"> {{ item.description }}</td>
            </tr>
            </tbody>

            <tfoot>
            <tr>
              <td colspan="2">
                Note:
                <span class="text-danger"
                >payable at the Inter-bank rate of the week</span
                >
              </td>
            </tr>
            </tfoot>
          </table>
          <p>
            This is subject to change.A complete enrollment/fee breakdown is
            available, on request, from the Admissions Executive. <br/>
            Send email to
            <router-link to="/mailto:admissions@southmewshigh.com"
            >admissions@southmewshigh.com
            </router-link>
          </p>

        </div>
        <div class="col-md-11 about-us">

          <bankDetails/>

          <div class="row ">
            <div class="col-md-12 type ">
              <h3 class="">You can also use paynow </h3>
              <h1></h1>
              <div class=" row d-flex flex-column center-align">
                <div class="col-md-6 center-align">
                  <img src="../assets/images/Paynow Badge-vector-hires DARK.svg" alt="paypal images">
                </div>
                <div class="col-md-6 ">
                  <router-link to="/payments" class=" center-align">
                  <p class="btn btn-success">Pay fees</p>
                  </router-link>

                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
import BankDetails from "../components/bankDetails.vue";

export default {
  components: {
    BankDetails
  },
  data() {
    return {
      feesStructure: [
        {
          amount: '$10',
          description: 'Processing and registration fee for all applications',
        },
        {
          amount: '4,500.00',
          description: 'The current acceptance of the Place fee is for Form One to Three',
        },
        {
          amount: '$2,250.00',
          description: 'The current acceptance of the Place fee is for Form Four to Upper Sixth',
        },
        {
          amount: '$5,000.00',
          description: 'Current school fees per term',
        },
      ],
    }
  }
};
</script>

<style scoped>

.about-us {

  background-color: #090051;

}


div {
  transition: all 1s ease-in-out 0s;
}


.table {
  height: 350px;
  width: 100%;
}
.table tr {
  font-size: 16px;
}


tfoot {
  color: #FFffff;
  font-size: 16px;
  text-align: center;
}



h1, h3 {
  color: #FFffff;
  text-align: center;


}

p {
  color: #FFffff;
  font-size: 16px;
  line-height: 1.5;


}

.type svg {
  overflow: hidden;
  vertical-align: bottom;
}

.type img {
  border-radius: 8px;
  width: 290px;


}

.button {
  max-width: 50%;
  height: 100px;
  background: none;

}


</style>