

<template>

      <div class="content container-fluid">
        <div v-if="loading" class="justify-content-center align-content-center  ">
          <div class="col-md-12 ">
            <spinner/>
            <h4 style="text-align: center">Wait a moment ,Loading your data..</h4>
          </div>
        </div>
        <div v-else-if="error" class="justify-content-center align-content-center  ">
          <div class="col-md-12 ">
            <h4  style="text-align: center ;color:red">{{error}}</h4>
          </div>
        </div>

        <div class="row"  v-else>
          <div class="col-md-8">
            <div class="blog-view">
              <article class="blog blog-single-post">
                <h3 class="blog-title">{{ event.title }}</h3>
                <div class="blog-info clearfix">
                  <div class="post-left">
                    <ul>
                      <li><router-link to="#"><i class="far fa-calendar-alt" aria-hidden="true"></i> <span>{{ event.date }}</span></router-link></li>
                      <li><router-link to="#"><i class="fa fa-clock" aria-hidden="true"></i> <span >12:00pm - 02:00pm</span></router-link></li>
                    </ul>
                  </div>

                </div>
                <div class="blog-image">
                  <router-link to="#"><img alt="" src="" class="img-fluid"></router-link>
                </div>
                <hr>
                <div class="blog-content">
                  <p>{{event.description}}</p>



                </div>
              </article>


            </div>
          </div>
          <aside class="col-md-4 ">
            <div class="widget blog-share clearfix  d-flex justify-content-center flex-column">
              <h3>Share the post</h3>
              <ul class="social-share mb-4">
                <li><router-link to="#" title="Facebook"><i class="fab fa-facebook-f"></i></router-link></li>
                <li><router-link to="#" title="Twitter"><i class="fab fa-twitter"></i></router-link></li>
                <li><router-link to="#" title="Linkedin"><i class="fab fa-linkedin-in"></i></router-link></li>
                <li><router-link to="#" title="Google Plus"><i class="fab fa-google-plus-g"></i></router-link></li>
                <li><router-link to="#" title="Youtube"><i class="fab fa-youtube"></i></router-link></li>
              </ul>
              <div class="widget post-widget">
                <h5>Tags</h5>
                <ul class="tags">
                  <li><router-link to="#" class="tag">{{ event.category }}</router-link></li>
                  <li><router-link to="#" class="tag">{{ event.subCategory }}</router-link></li>

                </ul>
              </div>
            </div>
            <div class="widget post-widget">
              <h5>Latest posts</h5>
              <ul class="laTest-posts" v-for="event in events" :key="event.eventId">
                <li>
                  <div class="post-thumb">
                    <router-link :to="`/EventDetails/${event.eventId}`">
                      <img class="img-fluid" src="../../src/assets/images/user.png" alt="">
                    </router-link>
                  </div>
                  <div class="post-info">
                    <h4>  <router-link :to="`/EventDetails/${event.eventId}`">{{event.title}} </router-link></h4>
                    <p>
                      {{ event.description.slice(0,50) + "...." }}

                    </p>
                    <p><i aria-hidden="true" class="fas fa-calendar-alt"></i>{{event.date }}</p>
                  </div>
                </li>




              </ul>
            </div>


          </aside>
        </div>
      </div>

</template>

<script>


import {services} from "@/services/axios";
import spinner from "@/components/spinner.vue";

export default {
  components: {spinner},
  data() {
    return {
      loading:"",
      error:"",
      event: {},
      events: [
        {
          "eventId": 1,
          "description": "A fascinating annual tech conference showcasing the latest innovations.",
          "category": "Technology",
          "dateCreated": "June 15, 2023",
          "date": "September 10, 2023",
          "image": "https://example.com/tech-conference.jpg",
          "status": "Upcoming",
          "subCategory": "Software",
          "title": "Tech Expo 2023"
        },
        {
          "eventId": 2,
          "description": "A vibrant music festival featuring both local and international artists.",
          "category": "Entertainment",
          "dateCreated": "April 20, 2023",
          "date": "July 25, 2023",
          "image": "https://example.com/music-festival.jpg",
          "status": "Ongoing",
          "subCategory": "Music",
          "title": "Summer Beats Festival"
        },
        {
          "eventId": 3,
          "description": "An educational symposium discussing the latest advancements in renewable energy solutions.",
          "category": "Environment",
          "dateCreated": "February 1, 2023",
          "date": "November 12, 2023",
          "image": "https://example.com/energy-symposium.jpg",
          "status": "Upcoming",
          "subCategory": "Energy",
          "title": "Renewable Energy Symposium"
        },
        {
          "eventId": 4,
          "description": "A culinary extravaganza showcasing the diverse flavors of the region.",
          "category": "Food",
          "dateCreated": "July 1, 2023",
          "date": "August 18, 2023",
          "image": "https://example.com/food-festival.jpg",
          "status": "Upcoming",
          "subCategory": "Cuisine",
          "title": "Flavor Fest 2023"
        },
        {
          "eventId": 5,
          "description": "An art exhibition featuring the works of renowned local and international artists.",
          "category": "Arts",
          "dateCreated": "March 15, 2023",
          "date": "June 30, 2023",
          "image": "https://example.com/art-exhibition.jpg",
          "status": "Completed",
          "subCategory": "Visual Arts",
          "title": "Artistic Expressions"
        }
      ],
      eventId:this.$route.params.eventId
    }
  },
  async created (){
    // try {
    //
    //   this.loading=true
    //
    //   let response=await services.getEvent(this.eventId)
    //   let responseAll=await services.getAllEvents()
    this.event = this.events.find((event) => event.eventId === parseInt(this.eventId));
    console.log('event', this.event);
    console.log('eventId', this.eventId);
    //   this.events=responseAll.data
    //  this.loading=false
    // }catch (e) {
    //   this.loading=true
    //   this.error=e
    //
    //   this.loading= false
    //
    // }
  }

};
</script>

<style scoped>



</style>