<template>

    <navbar />
  <router-view />



  <footerView />
</template>

<script >
import navbar from "./components/navbar.vue";
import footerView from "./components/footerView.vue";
export default {
  name: "App",
  components: {
    navbar,
    footerView,
  },
};
</script>

<style >

#app {
  font-family: sans-serif, Helvetica, Arial, sans-serif;
  font-size: 1.08rem;
  color: #2c3e50;
  margin-top: 7.4rem;
}


</style>
