<template>
  <section>
    <div class="container-fluid">
      <h1 class="heading">Meet Our Teachers</h1>

      <div class="row">
        <div class="col-md-4" v-for="teacher in teachers" :key="teacher.teacherId">
          <div class="card-wrapper">
          <div class="card">
            <img src="../assets/images/background.jpg" alt="card background" class="card-img">
            <img src="../assets/images/teacher.jpg" alt="profile images" class="profile-img">
            <h1>{{ teacher.firstName }} {{teacher.lastName}}</h1>
            <p class="job-title">{{ teacher.designation }}</p>
            <p class="about">
              {{teacher.description
              }}
            </p>
            <router-link to="#" class="btn">contact</router-link>
            <ul class="social-media">
              <li><router-link :to="teacher.contactDetails.facebook"><i class="fab fa-facebook-square"></i></router-link></li>
              <li><router-link :to="teacher.contactDetails.twitter"><i class="fab fa-twitter-square"></i></router-link></li>
              <li><router-link :to="teacher.contactDetails.instagram"><i class="fab fa-instagram"></i></router-link></li>
            </ul>
          </div>

        </div></div>
      </div>

    </div>
  </section>
   
</template>
<script>

import spinner from "@/components/spinner.vue";
import {services} from "@/services/axios";

export default {
  components:{
    spinner
  },
  data(){
    return{
     teachers : [
        {
          teacherId: 1,
          firstName: "John",
          lastName: "Doe",
          designation: "Math Teacher",
          description: "John is a passionate math teacher with over 10 years of experience. He is dedicated to helping students excel in mathematics.",
          contactDetails: {
            facebook: "#",
            twitter: "#",
            instagram: "#"
          }
        },
        {
          teacherId: 2,
          firstName: "Jane",
          lastName: "Smith",
          designation: "English Teacher",
          description: "Jane is a skilled English teacher who loves to inspire her students to explore the world of literature.",
          contactDetails: {
            facebook: "#",
            twitter: "#",
            instagram: "#"
          }
        },
        {
          teacherId: 3,
          firstName: "Michael",
          lastName: "Johnson",
          designation: "Science Teacher",
          description: "Michael is a knowledgeable science teacher who is committed to helping students develop a deep understanding of scientific concepts.",
          contactDetails: {
            facebook: "#",
            twitter: "#",
            instagram: "#"
          }
        }
      ],
      loading:false,
      error:""
    }
  },
  async created () {
    // try {
    //   this.loading=true
    //   let response=await services.getAllTeachers()
    //
    //   this.teachers=response.data
    //   console.log(teachers)
    //   this.loading=false
    // }
    // catch (e) {
    //   this.loading=true
    //
    //   this.error=e
    //   this.loading=false
    // }


  }

};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --themeColor: #2cdb;
}



a {
  text-decoration: none;
  color: #fff;
}

.heading {
  text-transform: uppercase;
  font-size: 3.5rem;
  letter-spacing: 3px;
  margin-right: -3px;
  margin-bottom: 5rem;
  text-align: center;
  color: #333;
  position: relative;
}

.heading::after {
  content: "";
  width: 10rem;
  height: 0.8rem;
  background: var(--themeColor);
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2rem;
}

.card-wrapper {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.card {

  background: #333;
  display: flex;

  align-items: center;
  justify-content: center;


}

.card .card-img {
  width: 100%;
  height: 19rem;
  object-fit: cover;

}

.profile-img {
  width: 20rem;
  height: 20rem;
  object-fit: cover;
  border-radius: 50%;
  margin-top: -13rem;
  z-index: 1;
  border: 1rem solid #333;
}

.card h1 {
  font-size: 2.5rem;
  color: #ccc;
  margin: 1.5rem 0;
}

.job-title {
  color: #aaa;
  font-size: 1.7rem;
  font-weight: 300;
  margin-top: -15px;
}

.about {
  font-size: 1.5rem;
  margin: 1.5rem 2rem;
  line-height: 2rem;
  text-align: justify;
  color: #ccc;
}

.card .btn {
  padding: 1.5rem 4rem;
  background: var(--themeColor);
  border-radius: 3rem;
  margin: 1rem 0 2rem;
  text-transform: uppercase;
  color: #eee;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 0.3rem;
  transition: 0.5s;
}

.card .btn:hover {
  transform: scale(1.05);
  box-shadow: 0.4rem 0.4rem 2rem rgba(255, 255, 255, 0.3);
}

.card .btn:active {
  transform: scale(1);
  box-shadow: none;
}

.social-media {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  padding: 3rem 0;
  margin-top: 1rem;
  border-top: 2px dashed rgba(255, 255, 255, 0.2);
}

.social-media i {
  font-size: 3.5rem;
  transition: 0.3s;
}

.fa-facebook-square {
  color: #3b5999;
}

.fa-twitter-square {
  color: #55acee;
}

.fa-instagram {
  color: #e6683c;
}

.fa-facebook-square:hover,
.fa-twitter-square:hover,
.fa-instagram:hover {
  color: var(--themeColor);
}

@media screen and (min-width: 700px) {
  .card-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .card {
    margin: 2rem;
    transition: 0.5s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .card:nth-child(1) {
    animation: fadeIn 0.5s 0.5s backwards;
  }
  .card:nth-child(2) {
    animation: fadeIn 0.5s 1s backwards;
  }
  .card:nth-child(3) {
    animation: fadeIn 0.5s 1.5s backwards;
  }
  .card:nth-child(4) {
    animation: fadeIn 0.5s 2s backwards;
  }
  .card:hover {
    transform: scale(1.05);
  }
}

</style>