

<template>

      <div class=" container-fluid">
        <div v-if="loading" class="justify-content-center align-content-center  ">
          <div class="col-md-12 ">
            <spinner/>
            <h4 style="text-align: center">Wait a moment ,Loading your data..</h4>
          </div>
        </div>
        <div v-else-if="error" class="justify-content-center align-content-center  ">
          <div class="col-md-12 ">
          <h4  style="text-align: center ;color:red">{{error}}</h4>
            </div>
        </div>

        <div class="row" v-else>
          <div class="col-sm-6 col-md- col-lg-4" v-for="event in events" :key="event.eventId">
            <div class="blog grid-blog">
              <div class="blog-image">
                <a href="#"><img class="img-fluid" src="../../src/assets/images/user.png" alt=""></a>
              </div>
              <div class="blog-content">
                <h3 class="blog-title"><a href="/EventDetails">{{event.title}}</a></h3>

                <p>{{event.description.slice(0,400) + "...." }}</p>
                <router-link :to="`/EventDetails/${event.eventId}`" class="read-more">
                  <i class="fas fa-long-arrow-alt-right" aria-hidden="true"></i>
                  Read More</router-link>
                <div class="blog-info clearfix">
                  <div class="post-left">
                    <ul>
                      <li><a href="#"><i class="far fa-calendar-alt" aria-hidden="true"></i>
                        <span>{{ event.date }}</span> <br>
                        <span class="ml-4">12:00pm - 02:00pm</span>
                      </a></li>
                    </ul>
                  </div>
                  <div class="post">
                    <span class=" tags">
                      <span style="text-align: center;font-size: medium">tags</span>
                      <br>
                      <span class="smallTags">{{   event.category }}</span> # <span class="smallTags">{{event.subCategory}}</span> </span>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




</template>

<script>

import spinner from "@/components/spinner.vue";
import {services} from "@/services/axios";

export default {
  components:{
    spinner
  },
  data(){
    return{
      events: [
        {
          "eventId": 1,
          "description": "A fascinating annual tech conference showcasing the latest innovations.",
          "category": "Technology",
          "dateCreated": "June 15, 2023",
          "date": "September 10, 2023",
          "image": "https://example.com/tech-conference.jpg",
          "status": "Upcoming",
          "subCategory": "Software",
          "title": "Tech Expo 2023"
        },
        {
          "eventId": 2,
          "description": "A vibrant music festival featuring both local and international artists.",
          "category": "Entertainment",
          "dateCreated": "April 20, 2023",
          "date": "July 25, 2023",
          "image": "https://example.com/music-festival.jpg",
          "status": "Ongoing",
          "subCategory": "Music",
          "title": "Summer Beats Festival"
        },
        {
          "eventId": 3,
          "description": "An educational symposium discussing the latest advancements in renewable energy solutions.",
          "category": "Environment",
          "dateCreated": "February 1, 2023",
          "date": "November 12, 2023",
          "image": "https://example.com/energy-symposium.jpg",
          "status": "Upcoming",
          "subCategory": "Energy",
          "title": "Renewable Energy Symposium"
        },
        {
          "eventId": 4,
          "description": "A culinary extravaganza showcasing the diverse flavors of the region.",
          "category": "Food",
          "dateCreated": "July 1, 2023",
          "date": "August 18, 2023",
          "image": "https://example.com/food-festival.jpg",
          "status": "Upcoming",
          "subCategory": "Cuisine",
          "title": "Flavor Fest 2023"
        },
        {
          "eventId": 5,
          "description": "An art exhibition featuring the works of renowned local and international artists.",
          "category": "Arts",
          "dateCreated": "March 15, 2023",
          "date": "June 30, 2023",
          "image": "https://example.com/art-exhibition.jpg",
          "status": "Completed",
          "subCategory": "Visual Arts",
          "title": "Artistic Expressions"
        }
      ],
      loading:false,
      error:""
    }
  },
  mounted: async function () {
//     try {
//       this.loading=true
//       let response=await services.getAllEvents()
//       this.events=response.data
//       this.loading=false
//     }
//     catch (e) {
//       this.loading=true
//
// this.error=e
//       this.loading=false
//     }


  }

};
</script>

<style scoped>
.blogThumb {
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.428571429;
  background-color: #fff;
  border: 1px solid #ddd
}
.blog {
  position: relative;
  margin: 0 0 20px;
  background-color: #fff;
  border: 1px solid #d5dbe1;
  border-radius: 10px;
  padding: 20px
}

.blog-image {
  overflow: hidden
}

.blog-image, .blog-image > a, .blog-image img {
  display: block;
  position: relative;
  width: 100%;
  height: auto
}

.blog-image img {
  -moz-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  -o-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -moz-transition: all .5s ease-in-out 0s;
  -ms-transition: all .5s ease-in-out 0s;
  -o-transition: all .5s ease-in-out 0s;
  -webkit-transition: all .5s ease-in-out 0s;
  transition: all .5s ease-in-out 0s
}

.blog-image a:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1)
}

.blog-image {
  margin-bottom: 30px
}

.blog-content {
  position: relative
}

.blog-title {
  color: #000;
  font-weight: 400;
  margin: 0 0 20px
}

.blog-content p {
  color: #282628;
  font-size: 14px;
  line-height: 25px;
  margin: 0 0 25px
}


.grid-blog .blog-title {
  font-size: 16px;
  margin: 0 0 10px
}

.grid-blog .blog-title a {
  color: #333
}

.grid-blog .blog-content p {
  font-size: 13px;
  margin: 0 0 15px;
  color: #909090
}

.grid-blog .blog-info {
  border: 0;
  border-top: 1px solid #eaeaea;
  margin-bottom: 0;
  padding: 12px 0
}

.grid-blog .blog-image {
  margin-bottom: 20px
}

.grid-blog .read-more {
  font-size: 13px;
  margin-bottom: 15px
}

.blog-view .blog-title {
  font-size: 32px
}

.blog-view .blog-info {
  border: 0;
  margin-bottom: 20px;
  padding: 0
}
.blog-reply > a {
  color: #2fdf84;
  font-size: 12px;
  font-weight: 500
}



.read-more {
  color: #282628;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 20px
}

.read-more:hover {
  color: #2fdf84;
  margin-left: 10px;
  -moz-transition: all .2s linear 0s;
  -ms-transition: all .2s linear 0s;
  -o-transition: all .2s linear 0s;
  -webkit-transition: all .2s linear 0s;
  transition: all .2s linear 0s
}

.read-more i {
  color: #2fdf84;
  font-size: 18px;
  margin-right: 3px
}


.post-left {
  float: left
}


.post .tags {

  padding:5px;
  float: right
}
.post-right a {
  padding-right:5px ;
  border-right: 1px solid whitesmoke;
}
.post-right a i:hover{
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4)
}
.post .tags .smallTags:hover{
  font-size:13px;
  color:lightsteelblue;
}


.post-left ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none
}

.post-left ul li {
  float: left;
  margin-right: 20px
}

.post-left ul li:last-child {
  margin-right: 0
}
</style>