

<template>
  <div class="main-wrapper">
    <div class="account-page">
      <div class="container">
        <div class="error-box text-center">
          <h1>404</h1>
          <h3><i class="fas fa-exclamation-triangle"></i> Oops! Page not found!</h3>
          <p>The page you Requested was not found.</p>
          <router-link to="/" class="btn btn-primary go-home">Go to Home</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageNotFound"
}
</script>
<style scoped>

</style>