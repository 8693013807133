<template>
  <div class="container">
    <div class="row mb-3 banking">
      <h3>Banking Details:</h3>
      <h1></h1>
      <div class="col-md-4" v-for="(bank, index) in banks" :key="index">
        <p>
          {{ bank.branch }} <br>
          ZWL$ Account - {{ bank.zwlAccount }} <br>
          US$ Account - {{ bank.usdAccount }}
        </p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      banks: [
        {
          branch: 'CBZ Chinhoyi Branch',
          zwlAccount: '123456789',
          usdAccount: '123456789',
        },
        {
          branch: 'ZB Bank Harare Branch',
          zwlAccount: '123456789',
          usdAccount: '123456789',
        },
        {
          branch: 'ECObank Harare Branch',
          zwlAccount: '123456789',
          usdAccount: '123456789',
        },
      ],
    };
  },
};
</script>

<style scoped>

.banking p {
  font-size: 12px;
  line-height: 2;
  padding-left: 12px;
  text-align: justify;
  font-weight: bold;
  color: #FFffff;
}
h3{
  border-bottom: 2px solid;
  text-align: center;
  color: #FFffff;

}
h1{
  margin-top: 8px;
}
</style>