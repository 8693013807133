<!--<template>-->
<!--  <div class="container-fluid">-->
<!--    <div class="row">-->
<!--      <div class="col-md-12 mt-2 mb-2 bg-secondary ">-->

<!--          <div class="mySlides" v-for="(slide, index) in slides" :key="index">-->
<!--          <div class="row d-flex ">-->
<!--            <div class="col-md-10 mt-2 mb-2  ">-->

<!--              <img :src="slide.image" alt="{{slide.alt}}" />-->
<!--            </div>-->
<!--            <div class="col-md-2 mb-2  mt-2  caption">-->
<!--              <div class="row d-flex flex-column">-->
<!--                <div class="col-md-12 mb-5 center-align">-->
<!--                  <img src="../assets/images/Southmews%20High%20Logo%201.jpg" alt="logo"  height="50px">-->
<!--                </div>-->
<!--                <div class="col-md-12   justify-content-between align-content-center">-->
<!--                  <div class="text">{{slide.caption}}</div>-->
<!--                </div>-->
<!--              </div>-->


<!--            </div>-->
<!--          </div>-->




<!--          </div>-->

<!--          <a class="prev" @click="plusSlides(-1)">❮</a>-->
<!--          <a class="next" @click="plusSlides(1)">❯</a>-->

<!--      </div>-->
<!--    </div>-->

<!--    <div style="text-align:center">-->
<!--      <span-->
<!--          class="dot"-->
<!--          v-for="(slide, index) in slides"-->
<!--          :key="index"-->
<!--          @click="currentSlide(index + 1)"-->
<!--          :class="{ 'active': index + 1 === slideIndex }"-->
<!--      ></span>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--      slideIndex: 1,-->
<!--      slides: [-->
<!--        {-->
<!--          image: require('@/assets/images/school2.jpg'),-->
<!--          caption: 'Top notch classes',-->
<!--          alt: 'school2'-->
<!--        },-->
<!--        {-->
<!--          image: require('@/assets/images/school1.jpg'),-->
<!--          caption: 'world class learning environment',-->
<!--          alt: 'image2'-->
<!--        },-->
<!--        {-->
<!--          image: require('@/assets/images/vanTrans.jpg'),-->
<!--          caption: 'We offer transport facilities',-->
<!--          alt: 'image3'-->
<!--        },-->
<!--        {-->
<!--          image: require('@/assets/images/students.jpg'),-->
<!--          caption: 'We create an environment were our student can be raised to the best version of them selves',-->
<!--          alt: 'image3'-->
<!--        }-->
<!--        ,-->
<!--        {-->
<!--          image: require('@/assets/images/corridor.jpg'),-->
<!--          caption: 'reception',-->
<!--          alt: 'image3'-->
<!--        }-->
<!--      ],-->
<!--      slideshowTimer: null-->
<!--    };-->
<!--  },-->
<!--  mounted() {-->
<!--    this.showSlides(this.slideIndex);-->
<!--    this.startSlideshow();-->
<!--  },-->
<!--  methods: {-->
<!--    plusSlides(n) {-->
<!--      this.showSlides(this.slideIndex + n);-->
<!--    },-->
<!--    currentSlide(n) {-->
<!--      this.showSlides(n);-->
<!--    },-->
<!--    showSlides(n) {-->
<!--      let i;-->
<!--      const slides = this.$el.getElementsByClassName('mySlides');-->
<!--      const dots = this.$el.getElementsByClassName('dot');-->
<!--      if (n > slides.length) {-->
<!--        this.slideIndex = 1;-->
<!--      } else if (n < 1) {-->
<!--        this.slideIndex = slides.length;-->
<!--      } else {-->
<!--        this.slideIndex = n;-->
<!--      }-->
<!--      for (i = 0; i < slides.length; i++) {-->
<!--        slides[i].style.display = 'none';-->
<!--      }-->
<!--      for (i = 0; i < dots.length; i++) {-->
<!--        dots[i].classList.remove('active');-->
<!--      }-->
<!--      slides[this.slideIndex - 1].style.display = 'block';-->
<!--      dots[this.slideIndex - 1].classList.add('active');-->
<!--    },-->
<!--    startSlideshow() {-->
<!--      this.slideshowTimer = setInterval(() => {-->
<!--        this.plusSlides(1);-->
<!--      }, 3000); // Change slide every 3 seconds-->
<!--    },-->
<!--    stopSlideshow() {-->
<!--      clearInterval(this.slideshowTimer);-->
<!--    }-->
<!--  },-->
<!--  beforeDestroy() {-->
<!--    this.stopSlideshow();-->
<!--  }-->
<!--};-->
<!--</script>-->

<!--<style scoped>-->

<!--body{-->
<!--  margin: 0;-->
<!--}-->

<!--.mySlides img{-->
<!--  width: 100%;-->
<!--  height: 400px;-->

<!--}-->
<!--.caption img{-->
<!--  width: 100px;-->
<!--  height: 105px;-->



<!--}-->
<!--/* Next & previous buttons */-->
<!--.prev, .next {-->
<!--  cursor: pointer;-->
<!--  position: absolute;-->
<!--  top: 60%;-->
<!--  width: auto;-->
<!--  padding: 16px;-->
<!--  margin-top: -22px;-->
<!--  color: white;-->
<!--  font-weight: bold;-->
<!--  font-size: 18px;-->
<!--  transition: 0.6s ease;-->
<!--  border-radius: 0 3px 3px 0;-->

<!--}-->

<!--/* Position the "next button" to the right */-->
<!--.next {-->
<!--  right: 10px;-->
<!--  border-radius: 3px 0 0 3px;-->
<!--}-->
<!--.prev {-->
<!--  left: 10px;-->
<!--  border-radius: 3px 0 0 3px;-->
<!--}-->

<!--/* On hover, add a black background color with a little bit see-through */-->
<!--.prev:hover, .next:hover {-->
<!--  background-color: rgba(0,0,0,0.8);-->
<!--}-->

<!--/* Caption text */-->
<!--.text {-->
<!--  color: #f2f2f2;-->
<!--  font-size: 15px;-->
<!--  padding: 8px 12px;-->
<!--margin-top: 40px;-->
<!--  bottom: 8px;-->
<!--  width: 100%;-->
<!--  text-align: center;-->
<!--}-->
<!--.caption{-->
<!--  background: #1f2836;-->
<!--}-->



<!--/* The dots/bullets/indicators */-->
<!--.dot {-->
<!--  cursor: pointer;-->
<!--  height: 15px;-->
<!--  width: 15px;-->
<!--  margin: 0 2px;-->
<!--  background-color: #bbb;-->
<!--  border-radius: 50%;-->
<!--  display: inline-block;-->
<!--  transition: background-color 0.6s ease;-->
<!--}-->

<!--.active, .dot:hover {-->
<!--  background-color: #717171;-->
<!--}-->

<!--/* On smaller screens, decrease text size */-->
<!--@media only screen and (max-width: 1000px) {-->
<!--  .mySlides img {-->
<!--    width: 100%;-->
<!--    height: 300px;-->
<!--    filter: blur(5px); /* Add this line to blur the image */-->
<!--  }-->

<!--  .caption {-->
<!--    background: #1f2836;-->
<!--    width: 100%; /* Add this line to make the caption take up the full width */-->
<!--  }-->

<!--  .caption .text {-->
<!--    font-size: 12px; /* Adjust the font size as needed */-->
<!--  }-->

<!--  .prev, .next {-->
<!--    cursor: pointer;-->
<!--    position: absolute;-->
<!--    top: 75%;-->
<!--    width: auto;-->
<!--    padding: 8px; /* Reduce the padding to make the buttons smaller */-->
<!--    color: black;-->
<!--    font-weight: bold;-->
<!--    font-size: 11px;-->
<!--    transition: 0.6s ease;-->
<!--    border-radius: 0 3px 3px 0;-->
<!--  }-->
<!--}-->
<!--</style>-->
<template>
  <section class="hero">
    <div class="hero-content">
      <h1>Elevate Your Potential</h1>
      <h2>Embark on an Exceptional Journey at Greenfield University</h2>
      <p>Greenfield University offers a transformative educational experience that empowers you to unlock your true potential. Immerse yourself in a vibrant community that nurtures academic excellence, fosters personal growth, and equips you with the skills to thrive in your future.</p>
      <p>Take the first step towards a brighter tomorrow. Discover the limitless possibilities that await you at Greenfield University</p>
    </div>
    <div class="hero-slider">
      <div class="slide" v-for="(slide, index) in slides" :key="index" :class="{ 'active': index === currentIndex }">
        <img :src="slide.image" :alt="slide.alt">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      slides: [
        {
          image: require('@/assets/images/school2.jpg'),
          title: 'Classes',
          caption: 'Top notch classes',
          alt: 'school2'
        },
        {
          image: require('@/assets/images/school1.jpg'),
          title: 'Environment',
          caption: 'World-class learning environment',
          alt: 'image2'
        },
        {
          image: require('@/assets/images/vanTrans.jpg'),
          title: 'Transport',
          caption: 'We offer transport facilities',
          alt: 'image3'
        },
        {
          image: require('@/assets/images/students.jpg'),
          title: 'Students',
          caption: 'We create an environment where our students can be raised to the best version of themselves',
          alt: 'image4'
        },
        {
          image: require('@/assets/images/corridor.jpg'),
          title: 'Staff',
          caption: 'Reception',
          alt: 'image5'
        }
      ],
      currentIndex: 0
    }
  },
  methods: {
    startSlider() {
      setInterval(this.showNextSlide, 5000);
    },
    showNextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
    }
  },
  mounted() {
    this.startSlider();
  }
}
</script>

<style scoped>
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80vh;
  background-color: #f5f5f5;
  padding: 0 2rem;
}
.hero-slider .slide {
  width: 100%;
  height: 100%;
  opacity: 0;

}

.hero-slider .slide.active {
  opacity: 1;
}
.hero-content {
  max-width: 600px;
  text-align: left;
}

.hero-content h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.hero-content h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.hero-content p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.hero-slider {
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.hero-slider .slide {
  width: 100%;
  height: 100%;
  animation: slideShow 10s infinite linear;
}

.hero-slider .slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes slideShow {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>

