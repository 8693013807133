<template>
  <div class="row">
    <div class="col-md-12"> <div class="footer-section">
      <div class="row m-3">
        <div class="col-md-4">
          <div class="footer-link">
            <h2 style="text-align: left">Southmews High</h2>
            <span class="heading-border"></span>
            <p>
              "Nurturing minds. Empowering futures.<br />
              We provide a dynamic and inclusive learning environment.<br />
              Our dedicated educators foster critical thinking, creativity, and
              character development. With personalized learning and a supportive
              community, we prepare students to become lifelong learners and
              responsible global citizens. Join us in shaping the leaders of
              tomorrow."
            </p>
            <ul class="social-icon">
              <li>
                <router-link to="#"><i class="fa fa-twitter"></i></router-link>
              </li>
              <li>
                <router-link to="https://wa.me/message/O4NAYVZIEDBEH1"><i class="fa fa-whatsapp"></i></router-link>
              </li>
              <li>
                <router-link to="#"><i class="fa fa-facebook"></i></router-link>
              </li>
              <li>
                <router-link to="#"><i class="fa fa-linkedin"></i></router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 ">
          <div class="footer-link">
            <h2>Featured Links</h2>
            <span class="heading-border"></span>
            <div class="col-md-12">
              <ul class="footer-menu">
                <li>
                  <router-link to="#"
                  ><i class="fa fa-graduation-cap"></i> Graduation</router-link
                  >
                </li>
                <li>
                  <router-link to="#"
                  ><i class="fa fa-bank"></i> Admission</router-link
                  >
                </li>
                <li>
                  <router-link to="#"
                  ><i class="fa fa-book"></i> Courses</router-link
                  >
                </li>
                <li>
                  <router-link to="#"
                  ><i class="fa fa-money"></i> Payments</router-link
                  >
                </li>
                <li>
                  <router-link to="#"
                  ><i class="fa fa-calendar"></i> Events</router-link
                  >
                </li>
                <li>
                  <router-link to="#"
                  ><i class="fa fa-user"></i> Student</router-link
                  >
                </li>
                <li>
                  <router-link to="#"
                  ><i class="fa fa-user"></i> Staff</router-link
                  >
                </li>
                <li>
                  <router-link to="#"
                  ><i class="fa fa-briefcase"></i> Careers</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="footer-link">
            <h2>Contact Info</h2>
            <span class="heading-border"></span>
            <ul class="footer-contact">
              <li>
                <i class="fa fa-envelope-o"></i>
                <span>EMAIL: info@southmewshigh.com</span>
              </li>
              <li>
                <i class="fa fa-whatsapp"></i>
                <span><router-link to="https://wa.me/message/O4NAYVZIEDBEH1"> WHATSAPP: +263776403559</router-link></span>
              </li>
              <li>
                <i class="fa fa-phone"></i>
                <span> CONTACT NO.: +263776403559</span>
              </li>
              <li>
                <i class="fa fa-globe"></i>
                <span
                ><router-link to=""
                >https://www.southmewshighschool.com</router-link
                ></span
                >
                <span> </span>
              </li>


            </ul>
          </div>
        </div>
        <!--      <div class="col-md-3">-->
        <!--        <div class="footer-link newsletter">-->
        <!--          <h2>Newsletter</h2>-->
        <!--          <span class="heading-border"></span>-->
        <!--          <p>Welcome,Signup for our Newsletter</p>-->
        <!--          <form @submit.prevent="subscribe()" >-->
        <!--            <div class="row d-flex">-->
        <!--              <div class="col-md-6">-->
        <!--                <input class="form-control" type="text" id="email" name="text" placeholder="First Name"  v-model="subscriber.firstname"/>-->
        <!--                <div class=" showError">{{firstnameError}}</div>-->
        <!--              </div>-->

        <!--              <div class="col-md-6 mt-2">-->
        <!--                <input class="form-control rounded-1" type="text" id="email" name="text" placeholder="Last Name"  v-model="subscriber.lastname"/>-->
        <!--                <div class=" showError">{{lastnameError}}</div>-->
        <!--              </div>-->

        <!--            </div>-->
        <!--            <input class="form-control mt-2 rounded-1" type="email" id="email" name="email" placeholder="E-mail"  v-model="subscriber.email"/>-->

        <!--            <div class=" showError">{{emailError}}</div>-->

        <!--            <button class="button"  type="submit" >Subscribe Now</button>-->
        <!--          </form>-->


        <!--        </div>-->
        <!--      </div>-->
      </div>

    </div></div>
    <div class="col-md-12"><div class="footer-bottom">

      <div class="row">
        <div class="col-md-12 ">
          <p class="rights d-flex justify-content-center align-content-center">
            &copy; 2024 <router-link to="">Southmews High</router-link> . All
            Rights Reserved.
          </p>
        </div>
      </div>
    </div></div>

  

  </div>

</template>



<style scoped>
.footer-section {
  background-color: #090051;
  padding-top: 16px;
  padding-bottom: 8px;
  width: 100%;
   
}
.footer-link h2 {
  color: #fff;
  text-align: center;
 
}
.footer-link hr {
  width: 270px;
}
.footer-link p {
  color: #fff;
  line-height: 16px;
  padding: 0 20px 0 0;
  text-align: left;
}
.social-icon {
  list-style: none;
  margin-top: 20px;

}
.social-icon li {
  float: left;
  padding-left: 10px;
  margin-bottom: 20px;
}
.social-icon li a i {
  color: #fff;
  font-size: 18px;
  padding: 5px 10px;
}
.social-icon .fa-twitter {
  background: #2ed1ff;
}
.social-icon .fa-whatsapp {
  background: #4caf50;
}
.social-icon .fa-facebook {
  background: #3b5998;
}
.social-icon .fa-linkedin {
  background: #3b5998;
}
.footer-menu {
padding: 0;
  width: 100%;
  float: left;
  list-style: none;
}
.footer-menu li {
     width: 50%;
     float: left;
     list-style: none;
     margin-bottom: 10px;
}

.footer-menu li a {
  color: #fff;
  text-decoration: none;
}
 .footer-contact {
     list-style: none;
     padding: 0;
     width: 100%;
     float: left;
     color: #fff;
}
 .footer-contact li {
     width: 100%;
     float: left;
     margin-bottom: 10px;
}
.footer-contact li a{
  text-decoration: none;  
  color: #fff; 
}
 .footer-contact span {
     padding-left:5px;
}
.newsletter p {
  margin-bottom: 10px;
}
.newsletter input {
  width: 100%;
  float: left;
  padding: 10px;
}
.newsletter button {
  width: 100%;
  float: left;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: gold;
  margin-top: 10px;
}
.footer-bottom {
  background: #43526b;


}

.footer-bottom p {

  color: #070606;
}

.rights a {
  color: #90a1dafd;
  cursor: pointer;
  text-decoration: none;
}
.rights a:hover {
  color: #c3d0db;
  text-decoration: none;
}

.fa {
  font-size: 24px;
  color: rgb(51, 51, 128);
  margin-right: 8px;
}
.showError {
  color: red;
  margin-top: 5px;
  font-size: 14px;
}
</style>
<script>
import {services} from "@/services/axios";

export default  {
  name:'footer',
    data (){
    return {
          subscriber:{
            firstname:'',
            lastname:'',
            email:'',
          },



      emailError:'',
      firstnameError:'',
      lastnameError:''
    }
    },
  methods: {
    subscribe: async function () {
      this.clearErrors();
      let isValid = true;


      if (this.subscriber.firstname=== '') {
        this.showError('firstname', 'Please enter your name.');
        isValid = false;
      }


      if (this.subscriber.lastname=== '') {
        this.showError('lastname', 'Please enter your last Name.');
        isValid = false;
      }


      if (this.subscriber.email==='') {
        this.showError('email', 'Please enter email address.');
        isValid = false;
      }

      else if (!this.isValidEmail(this.subscriber.email)) {
        this.showError('email', 'Please enter a valid email address.');
        isValid = false;
      }

      if (isValid) {
        try {
          let response= await services.saveSubscriber(this.subscriber)
          if (response){

            this.$router.push('/')
          }
          else{
            // this.$router.push('/')
          }

        }
        catch (error){

          console.log(error)

        }

      }
    },
    showError(field, message) {
      this[`${field}Error`] = message;
    },
    clearErrors() {

      this.firstnameError = '';
      this.lastnameError = '';
      this.emailError = '';


    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
  }
}


</script>