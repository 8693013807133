<template>
    <section class="vh-100  m-0"
      style="background-image: url('https://mdbcdn.b-cdn.net/img/Photos/new-templates/search-box/img4.webp');">
      <div class="mask d-flex align-items-center h-100 gradient-custom-3">
        <div class="container h-5 ">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-9 col-lg-7 col-xl-6">
              <div class="card" style="border-radius: 15px;">
                <div class="card-body ">
                  <h2 class=" text-center mb-2">Create an Account</h2>
    
                  <form>
    
                    <div class="form-floating mb-4">
                      <input type="text"  class="form-control form-control-lg place" placeholder="Enter Name" />
                      <label>Enter Name </label>
                      
                    </div>
    
                    <div class="form-floating mb-4">
                      <input type="email"  class="form-control form-control-lg" placeholder="Enter Email" />
                      <label>Enter Email </label>
                    </div>
                    
      
    
                    <div class="form-floating mb-4">
                      <input type="password"  class="form-control form-control-lg" placeholder="Enter Password" />
                      <label>Enter Password </label>
                    </div>
    
                    <div class="form-floating mb-2">
                      <input type="password" class="form-control form-control-lg" placeholder="Confirm Password" />
                      <label>Confirm Password</label>
                    </div>
    
                    <div class="form-check d-flex justify-content-center mb-2">
                      <input class="form-check-input " type="checkbox" value="" id="form2Example3cg" />
                      <label class="form-check-label" >
                        I agree all statements in <RouterLink to="#!" class="text-body"><u>Terms of service</u></RouterLink>
                      </label>
                    </div>
    
                    <div class="d-flex justify-content-center">
                      <button type="button"
                        class="btn btn-success  btn-lg gradient-custom-4 text-body">Register</button>
                    </div>
    
                    <p class="text-center text-muted mt-2 ">Have already an account? <RouterLink to="/"
                        class="fw-bold text-body"><u  > <span class="login" >Login here</span></u></RouterLink></p>
    
                  </form>
    
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
    
    <script>
    export default {
      name: 'registration',
      data(){
        return {
          
      }
      }}
     
    </script>
    <style scoped>
    .login{
      color:blue;
      font-size:14px;

    }
  </style>
    