

<template>
<div class="container-fluid">
  <div class="row all d-flex m-4">
    <div class="col-md-4 d-flex">
      <div class="row d-flex flex-column">
        <div class="col-md-12 mb-3">
          <div class="card">
            <div class="card-header">
              <h3>Student Details</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 bg-body-tertiary">
                  <img src="../assets/images/teacher.jpg" alt="student picture" class="card-img-top">
                </div>
                <div class="col-md-8">
                  <div class="form-group d-flex">
                    <p><strong>Name:</strong></p>
                    <p>tinotenda</p>
                    <p>Marufu</p>
                  </div>
                  <div class="form-group d-flex">
                    <p><strong>Form:</strong></p>
                    <p>Form</p>
                  </div>

                  <div class="form-group d-flex">
                    <p><strong>Term:</strong></p>
                    <p>term</p>
                  </div>



                  <div class="form-group d-flex">
                    <p><strong>Fees Balance:</strong></p>
                    <p>$24</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
        <div class="card mb-3">
          <div class="card-header">
            <h3>Transaction History</h3>
          </div>
          <div class="card-body">
            <table class="table table-borderless table-responsive">
              <thead>
              <tr>
                <td>Date</td>
                <td>Description</td>
                <td>Amount</td>
                <td>Status/action</td>
              </tr>
              </thead>
              <tbody>

              <tr v-for="tran in transactions" :key="tran.id" >
                <td class="justify-content-center align-content-center">{{ tran.date }}</td>
                <td class="justify-content-center align-content-center"> {{ tran.description }}</td>
                <td class="justify-content-center align-content-center"> {{ tran.amount }}{{tran.currency}}</td>
                <td class="justify-content-center align-content-center" >
                  <button v-if="tran.status === 'Failure'" class="btn btn-primary" @click="viewDetails(tran.id)">View </button>
                  <span v-else> {{ tran.status }}</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row  d-flex ">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header ">
              <h3>Payments</h3>
            </div>
            <div class="card-body">
              <form @submit.prevent="submitForm()">
              <div class="row ">

                <img src="../assets/images/Paynow%20Badge-vector-hires%20DARK.svg" alt="payments" height="100px">
                <div class=" d-flex">
                  <div class=" showError me-4">{{currencyError}}</div>

                </div>
                <div class="col-md-3">
                            <label class="sub-heading">Currency</label>
                            <div class="row d-flex">
                              <div class="col-md-6">
                                <div class="form-check mb-3">
                                  <input class="form-check-input" type="radio" name="currency" id="currencyUSD" value="usd" v-model="currency">
                                  <label class="form-check-label" for="currencyUSD">
                                    USD
                                  </label>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-check">
                                  <input class="form-check-input" type="radio" name="currency" id="currencyOther" value="other" v-model="currency">
                                  <label class="form-check-label" for="currencyOther">
                                    ZWL
                                  </label>
                                </div>
                              </div>
                            </div>








                </div>
                <div class="col-md-9">
                  <div class="row">
                    <h2>Enter Payment details</h2>

                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6">
                              <label class="form-label" for="Name">First Name</label>
                              <input class="form-control" type="text" id="Name" v-model="firstName" placeholder="name">
                            <div class=" showError">{{firstnameError}}</div>
                          </div>
                          <div class="col-md-6">
                            <label class="form-label" for="lastName">Last Name</label>
                            <input class="form-control" type="text" id="lastName" v-model="lastName">
                            <div class=" showError">{{lastNameError}}</div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <label class="form-label" for="email">Email</label>
                            <input class="form-control" type="email" id="email" v-model="email" placeholder="example@gmail.com">
                            <div class=" showError">{{emailError}}</div>
                          </div>
                          <div class="col-md-6">
                            <label class="form-label" for="phoneNumber">Phone Number</label>
                            <input class="form-control" type="tel" id="phoneNumber" v-model="phoneNumber" placeholder="07777777777">
                            <div class=" showError">{{phoneNumberError}}</div>
                          </div>
                          <div class="col-md-6">
                            <label for="email">Payment Description</label>
                            <select class="form-control" id="categories" v-model="paymentDescription">
                              <option value="100">TUITION FEES</option>
                              <option value="101">EXAM FEES</option>
                              <option value="102">FINES PENALTIES</option>
                              <option value="103">E_BUNDLE</option>
                              <option value="104">BOOKS</option>
                              <option value="105">VIRTUAL CLASS EQUIPMENT</option>
                              <option value="106">SCHOOL SUPPLIES</option>
                              <option value="107">UNIFORM</option>
                            </select>
                            <div class=" showError">{{paymentDescriptionError}}</div>
                          </div>

                          <div class="col-md-6">
                            <label for="amount">Amount to be Paid</label>
                            <input class="form-control" type="number" id="amount" v-model="amount" >
                            <div class=" showError">{{amountError}}</div>
                          </div>


                        </div>
                      </div>


                      <div class="d-flex justify-content-center mt-4">
                        <button
                            type="submit"
                            class="btn btn-primary btn-block btn-lg gradient-custom-4 text-body">
                          Pay
                        </button>
                      </div>

                  </div>
                </div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  data() {
    return {

      transactions: [
        {
          id: 1,
          date: '2024-03-18',
          description: 'Books Purchase',
          amount: 50.00,
          currency: 'USD',
          status: 'Success'
        },
        {
          id: 2,
          date: '2024-03-17',
          description: 'Tuition Fee Payment',
          amount: 500.00,
          currency: 'USD',
          status: 'Success'
        },
        {
          id: 3,
          date: '2024-03-16',
          description: 'Stationery Purchase',
          amount: 20.00,
          currency: 'USD',
          status: 'Failure'
        },
        {
          id: 4,
          date: '2024-03-15',
          description: 'Online Subscription',
          amount: 10.00,
          currency: 'USD',
          status: 'Pending'
        }
      ],
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      currency: '',
      paymentMethod: '',
     paymentDescription: '',
     amount: '',

      firstnameError: '',
      lastNameError: '',
      phoneNumberError: '',
      emailError:'',
      currencyError: '',
      paymentMethodError: '',
      paymentDescriptionError: '',

      amountError: '',


    };
  },
  methods: {
    submitForm: function () {
      this.clearErrors();
      let isValid = true;

      if (this.firstName=== '') {
        this.showError('firstname', 'Please enter your First name.');
        isValid = false;
      }


      if (this.lastName=== '') {
        this.showError('lastName', 'Please enter your last Name.');
        isValid = false;
      }

      if (this.phoneNumber=== '') {
        this.showError('phoneNumber', 'Please enter your Phone Number.');
        isValid = false;
      }

      if (!this.isValidEmail(this.email)) {
        this.showError('email', 'Please enter a valid email address.');
        isValid = false;
      }

      if (this.currency=== '') {
        this.showError('currency', 'Please select currency');
        isValid = false;
      }
      if (this.paymentMethod=== '') {
        this.showError('paymentMethod', 'Please select Payment Method ');
        isValid = false;
      }
      if (this.paymentDescription=== '') {
        this.showError('paymentDescription', 'Please select Payment Description');
        isValid = false;
      }
      if (this.amount=== '') {
        this.showError('amount', 'Please Enter amount');
        isValid = false;
      }
      else if(this.amount<=0){
        this.showError('amount', 'Amount must be greater then 0');
        isValid = false;
      }



      if (isValid) {
        // Perform any necessary actions here
        // For example, redirecting to a new page
        //this.$router.push('/
        window.alert('payment was a succesful')
      }
    },
    showError(field, message) {
      this[`${field}Error`] = message;
    },
    clearErrors() {
      this.firstnameError= '';
      this.lastNameError= '';
          this.phoneNumberError= '';
          this.emailError='';
          this.currencyError= '';
          this.paymentMethodError= '';
          this.paymentDescriptionError= '';
          this.amountError= ''
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
  }
};

</script>

<style scoped>
.all{
  font-size: 14px;
}
.sub-heading{
  font-weight: bold;
  font-size:12px;
}
.card-img-top{
  margin-top:30px;
  border-radius:40%;
 }

.showError {
  color: red;

  margin-top: 5px;
  font-size: 14px;
}
</style>