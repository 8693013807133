<!--<template>-->
<!--  <div  class="container" >-->
<!--    <h1>Southmews High History</h1>-->



<!--        <div class="row container-fluid" >-->
<!--        <div class="col-md-6">-->
<!--          <div class="slide">-->
<!--            <img src="../assets/images/images.jpg" alt="Passrate and Results" class="img-fluid">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-md-6">-->
<!--          <h2>Passrate and Results</h2>-->
<!--          <p>At Southmews High, we take pride in our students' exceptional pass rates and outstanding academic achievements. Our rigorous academic programs, coupled with the dedication and hard work of our students, have consistently resulted in impressive results. Our students consistently achieve high scores in standardized tests and excel in various academic competitions. We believe in nurturing a supportive and stimulating learning environment that encourages our students to reach their full potential and achieve academic excellence.</p>-->
<!--        </div>-->
<!--      </div>-->

<!--        <div class="row container-fluid">-->
<!--          <div class="col-md-6 ">-->
<!--            <h2>Virtual Tour</h2>-->
<!--            <p>Experience a virtual tour of our institution and get a glimpse of our state-of-the-art facilities. Explore our classrooms, laboratories, libraries, and recreational areas from the comfort of your own home. Our virtual tour provides an immersive and interactive way to see our campus and envision yourself as part of our vibrant learning community.</p>-->
<!--          </div>-->
<!--          <div class="col-md-6">-->
<!--            <div class="slide">-->
<!--              <img src="../assets/images/images.jpg" alt="Virtual Tour" class="img-fluid">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="row container-fluid">-->
<!--          <div class="col-md-6">-->
<!--            <div class="slide">-->
<!--              <img src="../assets/images/images.jpg" alt="Curriculum" class="img-fluid">-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-6">-->
<!--            <h2>Curriculum</h2>-->
<!--            <p>At Southmews High, we offer a comprehensive curriculum designed to provide students with a well-rounded education. Our curriculum combines academic excellence with practical skills development, ensuring that our students are prepared for success in their chosen fields. We offer a wide range of subjects and programs, allowing students to explore their interests and pursue their passions. Our dedicated faculty members are committed to delivering quality education and fostering a love for learning in our students.</p>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="row container-fluid">-->
<!--          <div class="col-md-6">-->
<!--            <h2>Frequently Asked Questions</h2>-->
<!--            <p>Have questions about our institution, programs, or admission process? We've got you covered. Check out our frequently asked questions section to find answers to common queries. We understand that choosing the right educational institution is an important decision, and we want to provide you with all the information you need. If you can't find the answer you're looking for, feel free to reach out to our friendly admissions team, who will be happy to assist you.</p>-->
<!--          </div>-->
<!--          <div class="col-md-6">-->
<!--            <div class="slide">-->
<!--              <img src="../assets/images/images.jpg" alt="Curriculum" class="img-fluid">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->


<!--</template>-->

<!--<script>-->

<!--export default {-->


<!--}-->
<!--</script>-->

<!--<style scoped>-->


<!--h1 {-->
<!--  margin-top: 8px;-->
<!--  text-align: center;-->

<!--}-->

<!-- h2 {-->
<!--  margin-top: 12px;-->
<!--  margin-bottom: 20px;-->
<!--}-->

<!--p {-->
<!--  margin-bottom: 4px;-->
<!--  font-size: 16px;-->
<!--  line-height: 1.5;-->
<!--  padding-left: 8px;-->
<!--  text-align: justify;-->
<!--}-->

<!--.slide {-->
<!--  height: 350px;-->
<!--  overflow: hidden;-->
<!--}-->

<!--.slide img {-->
<!--  width:100%;-->

<!--  object-fit: cover;-->
<!--  border-radius: 15px;-->
<!--  padding-top: 8px;-->
<!--  padding-left: 8px;-->
<!--  margin-bottom: 4px;-->
<!--}-->

<!--section {-->
<!--  margin-top: 40px;-->
<!--}-->

<!--section h2 {-->
<!--  font-size: 24px;-->
<!--  font-weight: bold;-->
<!--  margin-bottom: 10px;-->
<!--}-->

<!--section p {-->
<!--  font-size: 16px;-->
<!--  line-height: 2;-->
<!--}-->
<!--</style>-->
<template>
  <div id="home">
    <div class="" id="background-div"></div>
    <div class="about mb-5">
      <div class="container p-5 text-center about-us col-sm-10">
        <div class="row">
          <h1 class="display-6">About Us</h1>
          <h4 class="mb-5">Royalty Car Rental<br><br></h4>
          <p class="text-start mt-5" style="font-size: large;">Our vision is to become the most customer centric company in car rental industry. We will be persistent in building strong relationships with our customers and business partners.<br><br>
            We will be constantly focusing on innovation and delivery of simple high-quality service to our customers. <br><br>
            We are convinced that we need to innovate and control the high technology behind our services, and to participate only in markets where we can make significant contribution. <br><br>
            We don’t settle for anything less than excellence in every branch office and group in the company. We have courage to admit when and if we are wrong and courage to change. <br><br>
            We strive to create local opportunity, competitive advantage growth, and impact in every branch office around the World. <br><br>
            Our strategy is to build best-in-class IT platforms which will enable us to be productive and to deliver carefully customized services.
          </p>
        </div>
      </div>
    </div>
    <div class="mb-5 text-center">
      <h4 class="mb-4 display-5 animated">
        Our World</h4>
      <p class="obs mb-0 animated">
        Royalty Car Rental</p>
    </div>
    <div class="px-3 px-lg-0 map-stats d-flex flex-column flex-sm-row justify-content-evenly align-items-center mt-4">
      <div class="d-flex flex-column justify-content-center align-items-center mb-4">
        <span class="lh-1 mb-2 display-4 text-warning">
            1
        </span>

        <span>
            year operating
        </span>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center mb-4">
        <span class="lh-1 mb-2 display-4 text-warning">
            6
        </span>
        <span>
            countries
        </span>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center mb-4">
        <span class="lh-1 mb-2 display-4 text-warning">
            7
        </span>
        <span>
            branch offices
        </span>
      </div>
    </div>
    <!-- sections -->
    <div class="nav-pills nav-fill mt-3" id="sections">
      <!-- our vision dropdown -->
      <div class="nav-item text-start">
        <div class="nav-link page-sections sticky-top active"
             data-toggle="collapse" aria-label="Toggle-navigation"
             aria-expanded="true" data-target="ourVision"
             aria-controls="ourVision">
          01 Our Branches
        </div>
        <div class="row g-0" id="ourVision">
          <div class="col-md-6 text-white section-text">
            <div class="container-fluid pe-xl-0 h-100">
              <div class="d-flex flex-column justify-content-center align-items-start max-w-500 h-100 mx-auto ms-lg-0 me-lg-auto px-3 px-lg-3 py-5 py-xl-0">
                <h2 class="">Branch Offices</h2>
                <p>The business knowledge and know-how we generated over the decades in business worldwide, gave us
                  serious competitive advantage over other companies in the industry, especially rivals.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <img src="../assets/images/Southmews%20High%20Logo%201.jpg" class="img-fluid max-h-100-vh" alt="Our vision">
          </div>
        </div>
        <div class="container my-5 px-4">
          <div class="row text-start d-flex justify-content-space-between">
            <div class="col-lg-6 col-md-6 col-sm-12 my-3">
              <div class="container-fluid">
                <h3 class="border-bottom border-secondary pb-2">Africa</h3>
                <li><strong>Zimbabwe -</strong> Harare, Bulawayo</li>
                <li><strong>South Africa -</strong> Johannesburg</li>
                <li><strong>Angola -</strong> Luanda</li>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 my-3">
              <div class="container-fluid">
                <h3 class="border-bottom border-secondary pb-2">Europe</h3>
                <li><strong>United Kingdom -</strong> London</li>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 my-3">
              <div class="container-fluid">
                <h3 class="border-bottom border-secondary pb-2">Middle East</h3>
                <li><strong>United Arab Emirates -</strong> Dubai</li>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 my-3">
              <div class="container-fluid">
                <h3 class="border-bottom border-secondary pb-2">Australia</h3>
                <li><strong>Melbourne</strong></li>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Our Locations dropdown -->
      <div class="nav-item text-start">
        <div class="nav-link page-sections sticky-top active"
             data-toggle="collapse" aria-label="Toggle-navigation"
             aria-expanded="true" data-target="ourLocation"
             aria-controls="ourLocation">
          02 Worldwide Locations
        </div>
        <div class="row g-0" id="ourLocation">
          <div class="col-md-6 text-white section-text">
            <div class="container-fluid pe-xl-0 h-100">
              <div class="d-flex flex-column justify-content-center align-items-start max-w-500 h-100 mx-auto ms-lg-0 me-lg-auto px-3 px-lg-3 py-5 py-xl-0">
                <h2 class="">Worldwide Locations</h2>
                <p>We are a premium car rental group in Africa, Australia and the Middle East. It doesn't matter where you are located,
                  there will always be a convenient Royalty car rental branch nearby to help you continue your journery.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <img src="../assets/images/Southmews%20High%20Logo%201.jpg" class="img-fluid max-h-100-vh" alt="our locations">
          </div>
        </div>
        <div class="col-12 map-container w-100 bg-light-gray py-4 px-3 py-lg-9">
          <img src="../assets/images/Southmews%20High%20Logo%201.jpg" class="img-fluid max-h-100-vh" title="Royalty Car Rental">
        </div>
      </div>



    </div>
    <!-- end of navs -->


  </div>
  <div class="container my-5">
    <h3>Visit us at our location</h3>
    <a class="text-decoration-none text-dark" href="https://maps.app.goo.gl/SU6bUuAfL1exDXXH8">
      <div class="btn btn-warning">
        Royalty Car Rental<span class="bi bi-location"></span>
      </div>
    </a>
  </div>
  <div  class="container" >
    <h1>Southmews High History</h1>



    <div class="row container-fluid" >
      <div class="col-md-6">
        <div class="slide">
          <img src="../assets/images/images.jpg" alt="Passrate and Results" class="img-fluid">
        </div>
      </div>
      <div class="col-md-6">
        <h2>Passrate and Results</h2>
        <p>At Southmews High, we take pride in our students' exceptional pass rates and outstanding academic achievements. Our rigorous academic programs, coupled with the dedication and hard work of our students, have consistently resulted in impressive results. Our students consistently achieve high scores in standardized tests and excel in various academic competitions. We believe in nurturing a supportive and stimulating learning environment that encourages our students to reach their full potential and achieve academic excellence.</p>
      </div>
    </div>

    <div class="row container-fluid">
      <div class="col-md-6 ">
        <h2>Virtual Tour</h2>
        <p>Experience a virtual tour of our institution and get a glimpse of our state-of-the-art facilities. Explore our classrooms, laboratories, libraries, and recreational areas from the comfort of your own home. Our virtual tour provides an immersive and interactive way to see our campus and envision yourself as part of our vibrant learning community.</p>
      </div>
      <div class="col-md-6">
        <div class="slide">
          <img src="../assets/images/images.jpg" alt="Virtual Tour" class="img-fluid">
        </div>
      </div>
    </div>

    <div class="row container-fluid">
      <div class="col-md-6">
        <div class="slide">
          <img src="../assets/images/images.jpg" alt="Curriculum" class="img-fluid">
        </div>
      </div>
      <div class="col-md-6">
        <h2>Curriculum</h2>
        <p>At Southmews High, we offer a comprehensive curriculum designed to provide students with a well-rounded education. Our curriculum combines academic excellence with practical skills development, ensuring that our students are prepared for success in their chosen fields. We offer a wide range of subjects and programs, allowing students to explore their interests and pursue their passions. Our dedicated faculty members are committed to delivering quality education and fostering a love for learning in our students.</p>
      </div>
    </div>

    <div class="row container-fluid">
      <div class="col-md-6">
        <h2>Frequently Asked Questions</h2>
        <p>Have questions about our institution, programs, or admission process? We've got you covered. Check out our frequently asked questions section to find answers to common queries. We understand that choosing the right educational institution is an important decision, and we want to provide you with all the information you need. If you can't find the answer you're looking for, feel free to reach out to our friendly admissions team, who will be happy to assist you.</p>
      </div>
      <div class="col-md-6">
        <div class="slide">
          <img src="../assets/images/images.jpg" alt="Curriculum" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
}
</script>

<style scoped>
#home{
  background-color: rgb(241, 241, 241);
}
.about-us{
  margin-top: -180px;
  margin-bottom: 2rem;
  background-color: rgba(201, 201, 201, 0.85); /* Creamy color with 92.5% opacity */
  /* transition: all 1s ease-in-out 0s; */
}
#background-div {
  margin-top: -55px;
  margin-bottom: -20px;
  background: url("../assets/images/Southmews High Logo 1.jpg");
  background-size: cover !important;
  min-height: 90vh;
}
.page-sections{
  border-radius: 0;
  display: flex;
  justify-content: center;
  padding: 16px;
}
.sticky-top{
  top: 120px;
}
.section-text{
  background-color: #090051;
}
.img-fluid{
  object-fit: cover;
}
div{
  transition: all 1s ease-in-out 0s;
}
@media (min-width: 0px) and (max-width: 992px) {
  div.about-us{
    margin-top: -1rem;
  }
  #background-div{
    display: none;
    margin-top: 0px;
  }
}
@media (min-width: 0px) and (max-width: 500px) {
}
</style>

<!--<template>-->
<!--  <div>-->
<!--    <div class="nav-item text-start">-->
<!--      <div class="nav-link page-sections sticky-top active"-->
<!--           data-toggle="collapse" aria-label="Toggle-navigation"-->
<!--           aria-expanded="true" data-target="ourVision"-->
<!--           aria-controls="ourVision">-->
<!--        02 Our Vision-->
<!--      </div>-->
<!--      <div class="row g-0" id="ourVision">-->
<!--        <div class="col-md-6 text-white section-text">-->
<!--          <div class="container-fluid pe-xl-0 h-100">-->
<!--            <div class="d-flex flex-column justify-content-center align-items-start max-w-500 h-100 mx-auto ms-lg-0 me-lg-auto px-3 px-lg-3 py-5 py-xl-0">-->
<!--              <h2 class="">Our Vision</h2>-->
<!--              <p>Our Vision is to become one of the leading brands in the rental industry-->
<!--                of Africa and to build the wide network of branches in the World-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col-md-6">-->
<!--          <img src="../assets/images/images.jpg" class="img-fluid max-h-100-vh" alt="our vision">-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &lt;!&ndash; Section 1: Introduction &ndash;&gt;-->
<!--    <div class="introduction">-->
<!--      <h2>Mission Statement</h2>-->
<!--      <p>{{ missionStatement }}</p>-->
<!--      <h2>History</h2>-->
<!--      <div class="container-fluid">-->
<!--        <div class="row history m-1 ">-->
<!--          <h1>Southmews High History</h1>-->
<!--          <div class="col-md-6 left">-->
<!--            <h2>A Place to Thrive and Belong</h2>-->

<!--            <h3>Introduction</h3>-->

<!--            <p>-->
<!--              Our school may be relatively young, having been recently established ,-->
<!--              but it has quickly become a place where students and staff feel a sense-->
<!--              of belonging and thrive in their educational journey. This brief history-->
<!--              will shed light on the journey and values that have shaped our school-->
<!--              into a welcoming and inspiring learning community .-->
<!--            </p>-->

<!--            <ul>-->
<!--              <li>-->
<!--                <h4>1. The Vision and Foundation</h4>-->
<!--                Our school was founded with a clear vision in mind, to create an-->
<!--                environment where every student feels valued, supported, and inspired-->
<!--                to reach their full potential. Our founder recognized the importance-->
<!--                of creating a school that not only focuses on academic excellence but-->
<!--                also fosters a sense of community, inclusivity, and personal growth.-->
<!--              </li>-->
<!--              <li>-->
<!--                <h4>2. Building a Dedicated Team</h4>-->
<!--                From the very beginning, great care was taken to assemble a-->
<!--                <router-link to="/Teachers">Team</router-link> of passionate and-->
<!--                dedicated educators who share a common mission. Each staff member was-->
<!--                carefully selected for their expertise, commitment, and ability to-->
<!--                create a positive and nurturing learning environment. This team's-->
<!--                collective dedication has been instrumental in shaping the school's-->
<!--                culture and ensuring the success of our students.-->
<!--              </li>-->
<!--              <li>-->
<!--                <h4>3. Nurturing a Culture of Belonging</h4>-->
<!--                Creating a sense of belonging and community is at the heart of our-->
<!--                school's ethos. From day one, efforts were made to foster strong-->
<!--                relationships among students, teachers, and parents. Regular-->
<!--                community-building activities, such as orientation programs,-->
<!--                team-building exercises, and parent-teacher collaborations, have-->
<!--                helped create a warm and inclusive atmosphere where everyone feels-->
<!--                valued and respected.-->
<!--              </li>-->
<!--              <li>-->
<!--                <h4>4. Innovative Approaches to Education</h4>-->
<!--                Our school is committed to providing an education that is both-->
<!--                rigorous and relevant for the modern world. Embracing innovative-->
<!--                teaching methods and integrating technology into the classroom, we-->
<!--                strive to prepare our students for the challenges and opportunities of-->
<!--                the 21st century. Project-based learning, personalized instruction,-->
<!--                and a focus on critical thinking and problem-solving skills are key-->
<!--                elements of our educational approach.-->
<!--              </li>-->
<!--              <li>-->
<!--                <h4>5. Engaging Extracurricular Opportunities</h4>-->
<!--                Recognizing the importance of holistic development, our school offers-->
<!--                a wide range of extracurricular activities that cater to diverse-->
<!--                interests and talents. Students have the opportunity to participate in-->
<!--                sports, arts, clubs, and community service initiatives. These-->
<!--                activities not only provide an avenue for personal growth but also-->
<!--                foster teamwork, leadership skills, and a sense of pride in our school-->
<!--                community.-->
<!--              </li>-->
<!--            </ul>-->
<!--            <h5>Conclusion</h5>-->
<!--            <p>-->
<!--              Though our school may be in its infancy, it has quickly become a place-->
<!--              where students, parents, and staff feel a deep sense of connection and-->
<!--              pride. By nurturing a culture of belonging, fostering innovation in-->
<!--              education, and providing engaging extracurricular opportunities, we have-->
<!--              created an environment that inspires and empowers our students to thrive-->
<!--              academically, socially, and emotionally. As we continue to grow, we-->
<!--              remain committed to our vision of providing an exceptional educational-->
<!--              experience that prepares our students for a successful future.-->
<!--            </p>-->
<!--          </div>-->
<!--          <div class="col-md-6 right">-->
<!--            <img-->
<!--                src="../assets/images/school2.jpg"-->
<!--                alt="Southmews High History"-->
<!--            /><img-->
<!--              src="../assets/images/studentpic2.jpg"-->
<!--              alt="Southmews High History"-->
<!--          />-->
<!--            <img src="../assets/images/school1.jpg" alt="Southmews High History" />-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    &lt;!&ndash; Section 2: Leadership and Staff &ndash;&gt;-->
<!--    <div class="leadership-staff">-->
<!--      <h2>Leadership</h2>-->
<!--      <div v-for="leader in leaders" :key="leader.name">-->
<!--        <h3>{{ leader.name }}</h3>-->
<!--        <p>{{ leader.title }}</p>-->
<!--      </div>-->
<!--      <h2>Faculty and Staff</h2>-->
<!--      <div v-for="staff in staff" :key="staff.name">-->
<!--        <h3>{{ staff.name }}</h3>-->
<!--        <p>{{ staff.subject }}</p>-->
<!--      </div>-->
<!--    </div>-->

<!--    &lt;!&ndash; Section 3: Campus and Resources &ndash;&gt;-->
<!--    <div class="campus-resources">-->
<!--      <h2>Campus Information</h2>-->
<!--      <p>{{ campusInformation }}</p>-->
<!--      <h2>Extracurricular Activities</h2>-->
<!--      <ul>-->
<!--        <li v-for="activity in activities" :key="activity.name">-->
<!--          {{ activity.name }}-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->

<!--    &lt;!&ndash; Section 4: Achievements and Involvement &ndash;&gt;-->
<!--    <div class="achievements-involvement">-->
<!--      <h2>Achievements and Awards</h2>-->
<!--      <ul>-->
<!--        <li v-for="achievement in achievements" :key="achievement.name">-->
<!--          {{ achievement.name }}-->
<!--        </li>-->
<!--      </ul>-->
<!--      <h2>Community Involvement</h2>-->
<!--      <p>{{ communityInvolvement }}</p>-->
<!--    </div>-->

<!--    &lt;!&ndash; Section 5: Student and Parent Experience &ndash;&gt;-->
<!--    <div class="student-parent-experience">-->
<!--      <h2>Testimonials</h2>-->
<!--      <div v-for="testimonial in testimonials" :key="testimonial.name">-->
<!--        <h3>{{ testimonial.name }}</h3>-->
<!--        <p>{{ testimonial.quote }}</p>-->
<!--      </div>-->
<!--    </div>-->

<!--    &lt;!&ndash; Section 6: Stay Connected &ndash;&gt;-->
<!--    <div class="stay-connected">-->
<!--      <h2>Contact Information</h2>-->
<!--      <p>{{ contactInformation }}</p>-->
<!--      <h2>News and Updates</h2>-->
<!--      <ul>-->
<!--        <li v-for="news in news" :key="news.title">-->
<!--          {{ news.title }}-->
<!--        </li>-->
<!--      </ul>-->
<!--      <h2>Photo Gallery</h2>-->
<!--      <div class="gallery">-->
<!--        <img-->
<!--            v-for="image in images"-->
<!--            :key="image.src"-->
<!--            :src="image.src"-->
<!--            alt="Image"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--      missionStatement: "Our mission statement...",-->
<!--      history: "Our history...",-->
<!--      leaders: [-->
<!--        { name: "John Doe", title: "Principal" },-->
<!--        { name: "Jane Smith", title: "Vice Principal" },-->
<!--      ],-->
<!--      staff: [-->
<!--        { name: "Bob Johnson", subject: "Math" },-->
<!--        { name: "Alice Brown", subject: "English" },-->
<!--      ],-->
<!--      campusInformation: "Our campus information...",-->
<!--      activities: [-->
<!--        { name: "Football" },-->
<!--        { name: "Basketball" },-->
<!--      ],-->
<!--      achievements: [-->
<!--        { name: "Award 1" },-->
<!--        { name: "Award 2" },-->
<!--      ],-->
<!--      communityInvolvement: "Our community involvement...",-->
<!--      testimonials: [-->
<!--        { name: "John Doe", quote: "Quote 1" },-->
<!--        { name: "Jane Smith", quote: "Quote 2" },-->
<!--      ],-->
<!--      contactInformation: "Our contact information...",-->
<!--      news: [-->
<!--        { title: "News 1" },-->
<!--        { title: "News 2" },-->
<!--      ],-->
<!--      images: [-->
<!--        { src: "image1.jpg" },-->
<!--        { src: "image2.jpg" },-->
<!--      ],-->
<!--    };-->
<!--  },-->
<!--};-->
<!--</script>-->

<!--<style scoped>-->
<!--.history h1 {-->
<!--  margin-top: 12px;-->
<!--  text-align: center;-->
<!--  border-bottom: 2px solid;-->
<!--}-->
<!--.left h2 {-->
<!--  margin-top: 12px;-->
<!--  text-align: center;-->
<!--}-->
<!--.left h3 {-->
<!--  margin-top: 12px;-->
<!--  text-align: start;-->
<!--  text-decoration: underline;-->
<!--}-->
<!--.left p,-->
<!--li {-->
<!--  font-size: 16px;-->
<!--  text-align: justify;-->
<!--  padding-left: 8px;-->
<!--  line-height: 1.5;-->
<!--}-->
<!--ul {-->
<!--  margin-bottom: 20px;-->
<!--}-->
<!--ul li {-->
<!--  list-style-type: none;-->
<!--  margin-bottom: 12px;-->
<!--}-->
<!--a {-->
<!--  text-decoration: none;-->
<!--  cursor: pointer;-->
<!--  font-weight: bold;-->
<!--  color: rgb(7, 7, 245);-->
<!--  font-size: 16px;-->
<!--}-->
<!--img {-->
<!--  width: 100%;-->
<!--  height: 400px;-->
<!--  border-radius: 5px;-->
<!--  margin-bottom: 16px;-->
<!--}-->
<!--.sticky-top{-->
<!--  top: 120px;-->
<!--}-->
<!--.page-sections{-->
<!--  border-radius: 0;-->
<!--  display: flex;-->
<!--  justify-content: center;-->
<!--  padding: 16px;-->
<!--}-->
<!--.page-sections:hover{-->
<!--  cursor: default;-->
<!--}-->
<!--.section-text{-->
<!--  background-color: #090051;-->
<!--}-->
<!--</style>-->