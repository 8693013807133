<template>
  <div class="container bg-secondary bg-opacity-50">
    <div class="row mt-3">
      <div class="col-md-1 center-align">
        <img class="n" src="../assets/images/Southmews High Logo 1.jpg" alt="" />
      </div>
      <div class="col-md-11 box-heading center-align flex-column rounded-1">
        <h1>Southmews High School</h1>
        <h5>
          <i class="fa fa-phone me-2"></i>
          <span>+263776403559</span>
        </h5>
      </div>
      <h3 class="mt-4 center-align">STUDENT REGISTRATION FORM</h3>
    </div>


    <!-- Student Registration Form -->
    <form class="mt-3 mb-3" @submit.prevent="submitForm()">

      <div class="row center-align">
        <div class="col-md-8 center-align box-heading rounded mb-3 mt-3">
          <h1>Results</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <fieldset>
            <legend>Previous School Details</legend>

            <div class="form-group">
              <label class="form-label" for="school-name">School Name</label>
              <input
                  type="text"
                  class="form-control"
                  id="school-name"
                  name="school-name"

                  v-model="schoolName"
              />
              <div class=" showError">{{schoolNameError}}</div>
            </div>

            <div class="form-group">
              <label class="form-label" for="Level_Applied_For">Level last Attended</label>
              <select
                  class="form-control"
                  id="Level_Applied_For"
                  name="LevelApplied_For"

                  v-model="levelLastAttended"
              >
                <option value="" selected disabled>Select Level</option>
                <option value="Primary">Grade 7</option>
                <option value="Form 1">Form 1</option>
                <option value="Form 2">Form 2</option>
                <option value="Form 3">Form 3</option>
                <option value="Form 4">Form 4</option>
                <option value="Form 5">Form 5</option>
                <option value="Form 6">Form 6</option>
              </select>
              <div class=" showError">{{levelLastAttendedError}}</div>
            </div>

            <div class="form-group mt-2">
              <label class="form-label" for="school-results">Results (Upload)</label>
              <input
                  type="file"
                  class="form-control-file"
                  id="school-results"
                  name="school-results"
                  @change="onFileChange"
                  multiple
                  ref="schoolResults"
              />
              <div class=" showError">{{schoolResultsError}}</div>

            </div>

            <div class="form-group">
              <label class="form-label" for="additional-info">Any additional information</label>
              <textarea
                  class="form-control"
                  id="additional-info"
                  name="additional-info"
                  rows="4"
                  v-model="additionalInfo"
              ></textarea>

            </div>
          </fieldset>
        </div>
        <div class="col-md-6 rounded">
          <!-- Educational Details -->
          <fieldset class="">
            <legend>Educational Details</legend>

            <!-- Level Applied For -->
            <div class="form-group">
              <label for="Level_Applied_For">Level Applied For:</label>
              <select
                  class="form-control"
                  id="Level_Applied_For"
                  name="LevelApplied_For"

                  v-model="levelAppliedFor"
              >
                <option value="" selected disabled>Select Level</option>
                <option value="Form 1">Form 1</option>
                <option value="Form 2">Form 2</option>
                <option value="Form 3">Form 3</option>
                <option value="Form 4">Form 4</option>
                <option value="Form 5">Form 5</option>
                <option value="Form 6">Form 6</option>
              </select>
              <div class=" showError">{{levelAppliedForError}}</div>
            </div>

            <!-- Exam Board -->
            <div class="form-group">
              <label for="Exam_Board">Exam Board:</label>
              <select
                  class="form-control"
                  id="Exam_Board"
                  name="Exam_Board"

                  v-model="examBoard"
              >
                <option value="" selected disabled>Select Exam Board</option>
                <option value="zimsec">ZIMSEC</option>
                <option value="cambridge">Cambridge</option>

              </select>
              <div class=" showError">{{examBoardError}}</div>
            </div>
          </fieldset>
        </div>
        <div class="form-group center-align mt-3 mb-3">
          <button type="submit" class="button  ">
            Submit
          </button>
        </div>
      </div>



    </form>
  </div>
</template>

<script>
export default {

  data() {
    return {


      schoolName: '',
      levelLastAttended: '',
      schoolResults: '',
      additionalInfo: '',
      levelAppliedFor: '',
      examBoard: '',
      results: [],
      fileError: '',


      schoolNameError: '',
      levelLastAttendedError: '',
      schoolResultsError: '',
      additionalInfoError: '',
      levelAppliedForError: '',
      examBoardError: '',


    }
  },
  methods: {
    submitForm: function () {
      this.clearErrors();
      let isValid = true;

      // School Details
      if (this.schoolName === '') {
        this.showError('schoolName', 'Please enter your school name.');
        isValid = false;
      }

      if (this.levelLastAttended === '') {
        this.showError('levelLastAttended', 'Please enter the last level attended.');
        isValid = false;
      }

      if (this.results.length === 0 ) {
        this.showError('schoolResults', 'Please upload your school results.');
        isValid = false;
      }

      if (this.additionalInfo === '') {
        this.showError('additionalInfo', 'Please provide additional information.');
        isValid = false;
      }

      if (this.levelAppliedFor === '') {
        this.showError('levelAppliedFor', 'Please select the level applied for.');
        isValid = false;
      }

      if (this.examBoard === '') {
        this.showError('examBoard', 'Please select the exam board.');
        isValid = false;
      }



      if (isValid) {
        // Perform any necessary actions here
        // For example, redirecting to a new page
        //this.$router.push('/
        this.clearErrors()
        window.alert('registration was a succesful')
      }
    },
    onFileChange(event) {
      this.results = Array.from(event.target.files); // Update the selectedFiles array with the selected file(s)

    },

    showError(field, message) {
      this[`${field}Error`] = message;
    },
    clearErrors() {

      this.schoolNameError = '';
      this.levelLastAttendedError = '';
      this.schoolResultsError = '';
      this.additionalInfoError = '';
      this.levelAppliedForError = '';
      this.examBoardError = '';
    },

  }

};
</script>

<style scoped>
h3{
  color:rgb(0, 0, 0);
}
img {
  height: 95px;
}
label ,option,legend{
  font-size: 16px;
  font-weight: bold;
  color:rgb(0, 0, 0);


}


fieldset{
  margin-bottom: 12px;
}
.showError {
  color: crimson;
  margin-top: 5px;
  font-size: 14px;
}
</style>