<template>
  <div class="row principal center-align d-flex">
    <div class="col-md-6">

      <h4>Dear Parents/Guardians/Students</h4>
  <br />
  <p>
    , Our WhatsApp Number-
    <router-link to="https://wa.me/message/O4NAYVZIEDBEH1">+263776403559</router-link> Our
    high school is located in the vibrant community of Harare South, near
    Fidelity. Since our establishment in January 2024, we have been dedicated to
    providing a well-rounded education that prepares our students for the
    ever-changing technological world. <br>  At our school, we offer both the
    Cambridge and Zimsec curricula, ensuring that our students receive a
    comprehensive education that meets international standards. We also
    understand the importance of practical skills in today's society, which is
    why we place a strong emphasis on subjects such as agriculture, building,
    and computer sciences. <br> By offering these practical subjects, we aim to
    mentor our students to become not only academically successful but also
    young business leaders. In addition to our diverse curriculum, we are proud
    to offer a reliable and affordable transportation system for our students.
    Our school bus services cover various areas in Harare, ensuring that
    transportation is not a barrier to receiving a quality education. We are
    currently enrolling Form 1 , 2 and 5.Please come through for a tour. <br> For
    more information
    <router-link to="https://wa.me/message/O4NAYVZIEDBEH1">+263776403559</router-link>
  </p>
    </div>
    <div class="col-md-6">
<img src="../assets/images/images.jpg" alt="principal picture">
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>

.principal {
 margin-bottom: 16px;
  margin-top: 10px;
  font-size: 15px;
  line-height: 22px;

  text-align: justify;
  }
</style>