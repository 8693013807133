<template>
  <section
    class="vh-100 bg-image "
    style="
      background-image: url('https://mdbcdn.b-cdn.net/img/Photos/new-templates/search-box/img4.webp');
    "
  >
    <div class="mask d-flex align-items-center h-100 gradient-custom-3">
      <div class="container h-5">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-12 col-md-9 col-lg-7 col-xl-6">
            <div class="card" style="border-radius: 15px">
              <div class="card-body">
                <h2 class="text-center mb-2"> Student LogIn</h2>
                <div class="err">
                  <p class="error" v-if="error">{{ error }}</p>
                  <p class="success" v-if="success">{{ success }}</p>
                </div>

                <form @submit.prevent="login" id="loginForm">
                  <div class="form-floating mb-4">
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      v-model="email"
                      placeholder="Enter Email or Username"
                      id="usernameInput"
                    />
                    <label for="usernameInput">Enter Email or Username</label>
                  </div>

                  <div class="form-floating mb-4">
                    <input
                      type="password"
                      class="form-control form-control-lg"
                      v-model="password"
                      placeholder="Enter Password"
                      id="passwordInput"
                    />
                    <label for="passwordInput">Enter Password</label>
                  </div>

                  <div class="d-flex justify-content-center">
                    <button
                      type="submit"
                      class="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                    >
                      LOGIN
                    </button>
                  </div>

                  <p class="text-center text-muted mt-5 mb-0">
                    Don't have an account?
                    <router-link to="/registration" class="fw-bold text-body"
                      ><u>Register Here</u></router-link
                    >
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      type: "MERCHANT",
      fmcToken: "",
      error: null,
      success: null,
    };
  },
  methods: {
    async login() {
      const payload = {
        username: this.email,
        password: this.password,
        type: this.type,
      };

      try {
        const response = await fetch(
          "http://localhost:8080",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
        //  throw new Error("Check your network connection");
        this.$router.push({
path: "/acceptpayment"});
        }

        const data = await response.json();

        console.log("Login response:", data);

        if (data.C == 200) {
          console.log("Login successful");
          localStorage.setItem("token", data.M.accessToken);
         

          this.$router.push({
path: "/acceptpayment"});
        } else {
          console.log("Login failed");
          this.error = "Invalid email or password";
        }
      } catch (error) {
        console.error("There was a problem with the login:", error);
        this.error = error.message;
      }
    },
  },
};
</script>

<style scoped>
.err .error {
  margin-bottom: 5px;
  text-align: center;
  color: white;
  border-radius: 5px;
  padding: 10px;
  background-color: red;
}
.err .success {
  margin-bottom: 5px;
  text-align: center;
  color: white;
  border-radius: 5px;
  padding: 10px;
  background-color: green;
}
</style>