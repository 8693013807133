


<template>
<div class="container-fluid " style="
     background-image: url('https://mdbcdn.b-cdn.net/img/Photos/new-templates/search-box/img4.webp');
  ">
  <div class="row ">
    <div class="col-md-6 payment-form mb-5 mt-5 rounded-4">
      <div class="row ">
        <div class="col-md-12">

          <h1>School Payment Form</h1>
         <p>Your gateway to online payments</p>



        </div>
        <form action="" @submit.prevent="submitForm()" class="mb-3">
          <div class="col-md-12">

          <div class="row">
            <div class="col-md-6">
              <fieldset class="form-group form-floating">

                <input type="text" class="form-control" placeholder="First Name"  v-model="firstname" >
                <label class="form-label" >First Name</label>
                <div class="showError">{{firstnameError}}</div>
              </fieldset>

            </div>
            <div class="col-md-6">
              <fieldset class="form-group form-floating">

                <input type="text" class="form-control" placeholder="Last Name" id="lastname" name="lastname" v-model="lastName" >
                <label class="form-label" >Last Name</label>
                <div class="showError">{{lastNameError}}</div>

              </fieldset>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <fieldset class="form-group form-floating">

                <input type="text" class="form-control" placeholder="Student ID" id="studentID" name="studentID" v-model="studentID">
                <label class="form-label" >Student ID</label>
                <div class="showError">{{studentIDError}}</div>


              </fieldset>
            </div>
            <div class="col-md-6">
              <fieldset class="form-group form-floating">



                <select id="grade" name="grade" v-model="grade">
                  <option value="">Form Being Paid For: </option>
                  <option value="1">Form 1</option>
                  <option value="2">Form 2</option>
                  <option value="3">Form 4</option>
                  <option value="3">Form 5</option>
                  <option value="3">Form 6</option>


                </select>
                <div class=" showError">{{gradeError}}</div>
              </fieldset>
            </div>
          </div>

        </div>
          <div class="d-flex justify-content-center">
            <button
                type="submit"
                class="btn btn-success btn-block btn-lg gradient-custom-4 text-body"

            >
              submit
            </button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
</template>

<script>
import {services} from "@/services/axios";

export default {
  data() {
    return {
      firstname: '',
      lastName: '',
      studentID: '',
      grade: '',
      student:{},


      firstnameError: '',
      lastNameError: '',

      gradeError: '',
      studentIDError: '',



    };
  },

  methods: {
    submitForm: function () {
      this.clearErrors();
      let isValid = true;

      if (this.firstname=== '') {
        this.showError('firstname', 'Please enter your name.');
        isValid = false;
      }


      if (this.lastName=== '') {
        this.showError('lastName', 'Please enter your last Name.');
        isValid = false;
      }
      if (this.studentID=== '') {
        this.showError('studentID', 'Please enter your studentID.');
        isValid = false;
      }
      else
      if(this.studentID===''){
        this.showError('studentID', 'Student ID entered is invalid');
        isValid = false;
      }


      if (this.grade=== '') {
        this.showError('grade', 'Please enter the Form being paid for.');
        isValid = false;
      }if (this.year=== '') {
        this.showError('year', 'Please enter Year.');
        isValid = false;
      }






      if (isValid) {
     // try {
     //   let response=services.getStudentByStudentNumber(this.studentID)
     //
     //   this.student=response.data
     //   if (this.firstname!== this.student.firstname && this.lastName!==this.student.LastName &&
     //       this.studentID!==this.student.studentNumber ) {
     //       document.window.alert('no such student found')
     //
     // }
     //   else{
     //     this.$router.push(`/acceptpayment`);
     //   }
     // }
     // catch (e) {
     //
     // }
        this.$router.push(`/acceptpayment`);

      }
    },
    showError(field, message) {
      this[`${field}Error`] = message;
    },
    clearErrors() {
      this.firstnameError = '';
      this.lastNameError = '';
      this.studentIDError = '';

      this.gradeError = '';

    }
  }
};

</script>

<style scoped>


h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.payment-form {

  margin: 0 auto;
  background-color: rgb(255,255,255);
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group ,option[value=""]  {

  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}


.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #add1e1;
}

.form-group input[type="submit"] {
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.showError {
  color: red;
  margin-top: 5px;
  font-size: 14px;
}
</style>