<template>
  <div class="row my-4">
      <div class="col-md-12">
        <div class="banner">
          <h1 class="box-heading">Subject And Courses</h1>
        </div>
      </div>
    <div class="row  justify-content-center align-content-center">
      <div class="col-md-8 ">
        <p class="mt-3 text-center test ">
          At Southmews High School, our primary focus is to empower our students
          to achieve exceptional results in their examinations. What sets us
          apart is our commitment to providing a well-rounded education that
          combines traditional subjects with an excellent technical department.
          This unique approach allows our students to tailor their curriculum to
          their individual strengths, ensuring a comprehensive learning
          experience. At Southmews High School, we strive to equip our pupils
          with the skills and knowledge they need to excel in their academic
          pursuits
        </p>
      </div>
    </div>
    <div class="row d-flex justify-content-center align-content-center">
      <div class="subjectsDetails col-md-10 ">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
                class="nav-link active"
                id="olevel-tab"
                data-bs-toggle="tab"
                data-bs-target="#olevel"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
            >
              Ordinary-Level
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
                class="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#Alevel"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
            >
              Advanced-Level
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
                class="nav-link"
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#extra-curricular"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
            >
              Extra-carricular
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
              class="tab-pane fade show active"
              id="olevel"
              role="tabpanel"
              aria-labelledby="home-tab"
          >
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-12 d-block ">
                    <div class="row ">
                      <div class="col-md-4">
                        <div class="course-list">
                          <p><i class="fa fa-graduation-cap"></i>Sciencies</p>
                          <ul>
                            <li>Physics</li>
                            <li>Physical Science</li>
                            <li>Chemistry</li>
                            <li>Biology</li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="course-list">
                          <p><i class="fa fa-graduation-cap"></i>Arts</p>
                          <ul>
                            <li>English language</li>
                            <li>English literature</li>
                            <li>Bible Studies</li>
                            <li>History</li>
                            <li>Shona</li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="course-list">
                          <p><i class="fa fa-graduation-cap"></i>Commercials</p>
                          <ul>
                            <li>Business Studies</li>
                            <li>Commerce</li>
                            <li>Bible Studies</li>
                            <li>Principles of accounts</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="course-list">
                          <p><i class="fa fa-graduation-cap"></i>Practicals</p>
                          <ul>
                            <li>Food and Nutrition</li>
                            <li>Fashion and Textiles</li>
                            <li>Agricalture</li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="course-list">
                          <p><i class="fa fa-graduation-cap"></i>Technology</p>
                          <ul>
                            <li>ICT</li>
                            <li>Design Technology</li>
                            <li> Technical graphics</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
              class="tab-pane fade"
              id="Alevel"
              role="tabpanel"
              aria-labelledby="profile-tab"
          >
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-12 d-block">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="course-list bg-blue">
                        <p><i class="fa fa-graduation-cap"></i>Arts</p>
                        <ul>
                          <li>Divinity</li>
                          <li>English literature</li>

                          <li>History</li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="course-list bg-green">
                        <p><i class="fa fa-graduation-cap"></i>Technology</p>
                        <ul>
                          <li>Computer Science</li>
                          <li>Design and Technology</li>
                          <li>Technical graphics</li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="course-list bg-grey">
                        <p><i class="fa fa-graduation-cap"></i>Commercials</p>
                        <ul>
                          <li>Business Studies</li>

                          <li>Accounting</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="course-list">
                        <p><i class="fa fa-graduation-cap"></i>Sciencies</p>
                        <ul>
                          <li>Physics</li>
                          <li>Mathematics</li>
                          <li>Chemistry</li>
                          <li>Biology</li>
                          <li>Agriculture</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
              class="tab-pane fade"
              id="extra-curricular"
              role="tabpanel"
              aria-labelledby="contact-tab"
          >
            <div class="row d-flex">
              <div class="col-md-4 course-list">
                <p><i class="fa fa-graduation-cap"></i>Trips and Field Works</p>
                <ul>
                  <li>Trips for educational purposes and leisure</li>
                </ul>
              </div>

              <div class=" col-md-2 course-list">
                <p><i class="fa fa-graduation-cap"></i>Clubs</p>
                <ul>
                  <li>Debate clubs</li>
                  <li>Chess club</li>
                  <li>Choir</li>
                </ul>
              </div>

              <div class="col-md-6 course-list">
                <p class="center-align"><i class="fa fa-graduation-cap"></i>Sports</p>
                <div class="row d-flex">
                  <div class="col-md-6">
                    <ul>
                      <li><h5><strong>Atheletics</strong></h5></li>
                      <li>
                        <ul>
                          <li>track and field</li>
                          <li>Cross Country</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul>
                      <li><h5><strong>Ball Games </strong></h5></li>
                      <li>
                        <ul>
                          <li>Volleyball</li>
                          <li>Basketball</li>
                          <li>Soccer</li>
                          <li>Handball</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ExaminationBoards center-align mt-4 ">
      <div class="row ">
        <div class="col-md-6 mb-5 ">
          <h1 class="center-align">ZIMSEC</h1>
          <div class="row">
            <div class="col-md-12 backgroundZimsec ">
              <p class="cover ">
                ZIMSEC, an independent government agency operating under the
                Ministry of Primary and Secondary Education, is an
                internationally recognized examinations board. Its syllabuses
                have been evaluated and found to be equivalent to the General
                Certificate of Education Standard offered in various
                English-speaking countries. ZIMSEC's objective is to provide
                high-quality educational assessments and responsive awarding
                systems that prioritize value. Through rigorous assessment
                processes, ZIMSEC ensures the development of skilled and capable
                individuals to contribute to national growth. The council
                identifies and nurtures potential talents, competencies, and
                skills for future leaders in industries, commerce, and
                government sectors. ZIMSEC sets standards for academic
                excellence among Zimbabwean nationals, both domestically and
                internationally.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 ">
          <h1 class="center-align">Cambridge</h1>
          <div class="row">
            <div class="col-md-12  backgroundCambridge ">
              <p class="cover">
                The Cambridge IGCSE curriculum offers diverse pathways for
                learners with varying abilities, including those who are not
                native English speakers. It allows schools to customize their
                curriculum to meet specific requirements, starting with core
                subjects and expanding to incorporate interdisciplinary
                perspectives. Encouraging learners to explore multiple subjects
                and establish connections between them is a fundamental aspect
                of our approach. Cambridge IGCSE provides a flexible and
                enriching curriculum, supported by exceptional resources and
                training, enhancing learners' performance by fostering creative
                thinking, inquiry, and problem-solving skills. It serves as an
                excellent foundation for advanced studies, with Cambridge
                qualifications being widely recognized and valued by
                universities and employers globally.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.cover {
  margin-bottom: 2rem;
  background-color: rgba(201, 201, 201, 0.9);
  transition: all 1s ease-in-out 0s;
  padding: 8px 15px;
}

.banner {
  background: url(../assets/images/background.jpg);
  background-size: cover;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: left;
  color: #fff;
  font-size: 36px;
  text-align: left;
}

.subjectsDetails {
  background-color: #090051;
  border-radius: 0 0 10px 10px;
  margin-left: 16px;
  padding: 16px;


}

.head p, .ExaminationBoards p ,.test{
  text-align: center;
  font-size: 17px;
}

.course-list i {
  font-size: 30px;
}

.course-list p {
  font-size: 16px;
  margin-top: 10px;
  font-weight: bold;
}

.course-list ul li {
  list-style-type: none;
  text-align: left;
  font-size: 17px;
}

.course-list ul li:hover {
  color: #000;
  font-weight: bold;

}

.tab-content {
  background: #84b4cf;
  border-radius: 0 0 10px 10px;
}

button {
  background: rgb(75, 119, 169);
  color: black;
  font-size: 16px;
  margin-right: 4px;
  padding: 8px;
}

.backgroundCambridge {

  background-size: contain;
  background: url("../assets/images/Cambridge-International-Exams-logo.png") no-repeat;
  min-height: 280px;
  max-height: 55px;
  background-position-x: center;

}

.backgroundZimsec {
  background-size: contain;
  min-height: 280px;
  max-height: 55px;
  background: url("../assets/images/Zimsec-Logo.png") no-repeat center;
  background-position-x: center;
}

@media screen and (max-width: 1024px) {


}

@media screen and (max-width: 768px) {
  .backgroundCambridge {

    background-size: contain;
    background: url("../assets/images/Cambridge-International-Exams-logo.png") no-repeat center;
    min-height: 550px;
    max-height: 55px;

  }

  .backgroundZimsec {

    background-size: contain;
    min-height: 550px;
    max-height: 55px;
    background: url("../assets/images/Zimsec-Logo.png") no-repeat center;
  }


}

@media screen and (max-width: 425px) {

  .backgroundCambridge {

    background-size: contain;
    background: url("../assets/images/Cambridge-International-Exams-logo.png") no-repeat center;
    min-height: 490px;
    max-height: 55px;

  }

  .backgroundZimsec {

    background-size: contain;
    min-height: 490px;
    max-height: 55px;
    background: url("../assets/images/Zimsec-Logo.png") no-repeat center;
  }


}

@media screen and (max-width: 375px) {
  .backgroundCambridge {

    background-size: contain;
    background: url("../assets/images/Cambridge-International-Exams-logo.png") no-repeat center;
    min-height: 560px;
    max-height: 55px;

  }


}

@media screen and (max-width: 320px) {
  .backgroundCambridge {

    background-size: contain;
    background: url("../assets/images/Cambridge-International-Exams-logo.png") no-repeat center;
    min-height: 670px;
    max-height: 55px;

  }

  .backgroundZimsec {

    background-size: contain;
    min-height: 670px;
    max-height: 55px;
    background: url("../assets/images/Zimsec-Logo.png") no-repeat center;
  }
}
</style>