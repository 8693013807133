<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 ">
        <img class="d-block m-auto"
             src="../assets/images/spinner.webp" alt="">
      </div>
    </div>
  </div>



</template>

<script>
export default {
  name:'spinnerLoad',
}
</script>