<template >
  <div class="container-fluid">
    <div class="row history m-1 ">
      <h1>Southmews High History</h1>
      <div class="col-md-6 left">
        <h2>A Place to Thrive and Belong</h2>

        <h3>Introduction</h3>

        <p>
          Our school may be relatively young, having been recently established ,
          but it has quickly become a place where students and staff feel a sense
          of belonging and thrive in their educational journey. This brief history
          will shed light on the journey and values that have shaped our school
          into a welcoming and inspiring learning community .
        </p>

        <ul>
          <li>
            <h4>1. The Vision and Foundation</h4>
            Our school was founded with a clear vision in mind, to create an
            environment where every student feels valued, supported, and inspired
            to reach their full potential. Our founder recognized the importance
            of creating a school that not only focuses on academic excellence but
            also fosters a sense of community, inclusivity, and personal growth.
          </li>
          <li>
            <h4>2. Building a Dedicated Team</h4>
            From the very beginning, great care was taken to assemble a
            <router-link to="/Teachers">Team</router-link> of passionate and
            dedicated educators who share a common mission. Each staff member was
            carefully selected for their expertise, commitment, and ability to
            create a positive and nurturing learning environment. This team's
            collective dedication has been instrumental in shaping the school's
            culture and ensuring the success of our students.
          </li>
          <li>
            <h4>3. Nurturing a Culture of Belonging</h4>
            Creating a sense of belonging and community is at the heart of our
            school's ethos. From day one, efforts were made to foster strong
            relationships among students, teachers, and parents. Regular
            community-building activities, such as orientation programs,
            team-building exercises, and parent-teacher collaborations, have
            helped create a warm and inclusive atmosphere where everyone feels
            valued and respected.
          </li>
          <li>
            <h4>4. Innovative Approaches to Education</h4>
            Our school is committed to providing an education that is both
            rigorous and relevant for the modern world. Embracing innovative
            teaching methods and integrating technology into the classroom, we
            strive to prepare our students for the challenges and opportunities of
            the 21st century. Project-based learning, personalized instruction,
            and a focus on critical thinking and problem-solving skills are key
            elements of our educational approach.
          </li>
          <li>
            <h4>5. Engaging Extracurricular Opportunities</h4>
            Recognizing the importance of holistic development, our school offers
            a wide range of extracurricular activities that cater to diverse
            interests and talents. Students have the opportunity to participate in
            sports, arts, clubs, and community service initiatives. These
            activities not only provide an avenue for personal growth but also
            foster teamwork, leadership skills, and a sense of pride in our school
            community.
          </li>
        </ul>
        <h5>Conclusion</h5>
        <p>
          Though our school may be in its infancy, it has quickly become a place
          where students, parents, and staff feel a deep sense of connection and
          pride. By nurturing a culture of belonging, fostering innovation in
          education, and providing engaging extracurricular opportunities, we have
          created an environment that inspires and empowers our students to thrive
          academically, socially, and emotionally. As we continue to grow, we
          remain committed to our vision of providing an exceptional educational
          experience that prepares our students for a successful future.
        </p>
      </div>
      <div class="col-md-6 right">
        <img
            src="../assets/images/school2.jpg"
            alt="Southmews High History"
        /><img
          src="../assets/images/studentpic2.jpg"
          alt="Southmews High History"
      />
        <img src="../assets/images/school1.jpg" alt="Southmews High History" />

      </div>
    </div>
  </div>

</template>

<script>
export default {};
</script>

<style scoped>
.history {
  background: #597c9c;
  color: rgb(230, 224, 224);
}
.history h1 {
  margin-top: 12px;
  text-align: center;
  border-bottom: 2px solid;
}
.left h2 {
  margin-top: 12px;
  text-align: center;
}
.left h3 {
  margin-top: 12px;
  text-align: start;
  text-decoration: underline;
}
.left p,
li {
  font-size: 16px;
  text-align: justify;
  padding-left: 8px;
  line-height: 1.5;
}
ul {
  margin-bottom: 20px;
}
ul li {
  list-style-type: none;
  margin-bottom: 12px;
}
a {
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  color: rgb(7, 7, 245);
  font-size: 16px;
}
img {
  width: 100%;
  height: 400px;
  border-radius: 5px;
  margin-bottom: 16px;
}
</style>