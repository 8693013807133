<template>


    <nav class="navbar navbar-expand-lg navbar-light position-sticky bg-light ">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link to="/dashboard" class="nav-link">Dashboard</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/Results" class="nav-link">Results</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/attendance" class="nav-link">Pay Fees</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/communication" class="nav-link">Communication</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/news-and-events" class="nav-link">News and Events</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/resources" class="nav-link">Resources</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/account-settings" class="nav-link">Account Settings</router-link>
        </li>
      </ul>
    </nav>

</template>

<script>
  export default {
    name: "ParentsNav"
}
</script>
