<template>
  <div class="row center-align mt-3">
    <div class="col-md-12 w-75  ">
      <h1>APPLICATION PROCESS</h1>
  <h4>Southmews high School Applications</h4>
  <p>
    For any admissions related queries, please contact
    <router-link to="mailto:admissions@southmewshigh.com"
      >admissions@southmewshigh.com</router-link
    >
  </p>

  <div>
    <h4>Requisite</h4>
    <ul>
      <li>A copy of your child birth certificate.</li>
      <li>A passport size colour photo</li>
      <li>
        A list of your child academic, sporting and/or cultural achievements.
      </li>
    </ul>
    <p>
      For applications for Form 2 and above we also request a copy of your child
      most recent school report.
    </p>
    <p>
      You will receive an email and Form A (Intent to write the Common Entrance
      Exams) to complete and return to us within a specified time frame.
    </p>
    <p>
      This email will include details of the entrance exam centres, timings and
      what to bring/wear on the day.
    </p>
    <p>
      Two weeks after the entrance exam, notification is sent out advising
      whether your child has been offered a place.
    </p>
    <p>
      You will be given a specified time within which the Acceptance of Place
      Fee must be paid and requisite forms completed and returned to the
      College.
    </p>
    <p>
      Apply online or complete an official application form, which can be
      obtained from the Admissions Office,Southmews high school upon
      payment of a non-refundable application fee of US$20/(Equivalent RTGS) 
    </p>

    <p>
      Application forms can be downloaded from the School website:
      <router-link to="">www.southmews.ac.zw</router-link>. The downloaded
      application forms must be accompanied by a valid receipt or bank deposit
      slip marked (Application Fee). Application fees can be deposited into any
      of the following  Accounts:
      
    </p>

    <div class="row mb-5 banking ">

        <bank-details/>
      </div>

    <router-link to="/AdmissionForm" class="btn btn-success mt-3  mb-3">APPLY NOW</router-link>
  </div>
    </div>
  </div>
 
</template>

<script>
import BankDetails from "../components/bankDetails.vue";

export default {
  components: {BankDetails}
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 20px;
}

h3 {
  color: #333;
  font-size: 20px;
  margin-bottom: 10px;
}

ul {
  list-style-type: disc;
  margin: 0;
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 20px;
  cursor: pointer;
}

.btn:hover {
  background-color: #218838;
}
</style>