<template>
  <div class="report-card">
    <img src="../../../src/assets/images/Southmews%20High%20Logo%201.jpg" alt="School Logo">
    <h1>Academic Report Card</h1>
    <h2>{{ studentName }}</h2>

    <button @click="downloadReportCard">Download Report Card</button>
  </div>


  <div id="app" class="container mt-5">
    <!-- Academic Progress Report -->
    <div class="card mb-5">
      <div class="card-header">
        <h5 class="card-title">IGCSE ACADEMIC PROGRESS REPORT</h5>
      </div>
      <div class="card-body">
        <p class="card-text">Name: Homera Tatenda</p>
        <p class="card-text">Class Teacher: Mr. Jackson V</p>
        <p class="card-text">Form: 4 Diamond</p>
        <p class="card-text">Term: 2024 Term 1</p>
        <table class="table table-bordered mt-3">
          <thead>
          <tr>
            <th>Subject</th>
            <th>Mark %</th>
            <th>Grade</th>
            <th>Effort</th>
            <th>Class Average</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in tableData" :key="row[0]">
            <td>{{ row[0] }}</td>
            <td>{{ row[1] }}</td>
            <td>{{ row[2] }}</td>
            <td>{{ row[3] }}</td>
            <td>{{ row[4] }}</td>
          </tr>
          </tbody>
        </table>
        <p class="mt-3">Boarding Comment:</p>
        <p>A pleasant resident who has managed to integrate well with others.</p>
        <p>Rector's Comment:</p>
        <p>A pleasing work in progress status. Is committed and focused on her work. We expect to see further improvements.</p>
      </div>
    </div>

    <!-- Assessment Comments -->
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">IGCSE ASSESSMENT COMMENTS</h5>
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>Subject</th>
            <th>Teacher Comments</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in assessmentData" :key="row[0]">
            <td>{{ row[0] }}</td>
            <td>{{ row[1] }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
export default {
  data() {
    return {
      studentName: 'John Doe',
      grades: [
        { subject: 'Math', grade: 'A', credits: 4 },
        { subject: 'Science', grade: 'B', credits: 4 },
        { subject: 'English', grade: 'C', credits: 4 }
      ],

      tableData: [
        ["Science", "", "", "", ""],
        ["Mathematics", "50", "D", "CE", "66"],
        ["Combined Science", "45", "E", "UE", "45"],
        ["Commercials", "", "", "", ""],
        ["Business Studies", "50", "D", "UE", "66"],
        ["Arts", "", "", "", ""],
        ["Geography", "26", "G", "UE", "51"],
        ["English", "32", "F", "UE", "60"],
        ["Religious Education", "30", "F", "", "63"]
      ],
      assessmentData: [
        ["MATHEMATICS", "Unhappy score. Numerical comprehension is particularly low. Many rehearsals needed for catch up."],
        ["BIOLOGY", "Sad score. Reading comprehension is very low. Has to read more novels."],
        ["COMBINED SCIENCE", "Avoidable poor score. This work has to be redone. Show teacher every step of the way."],
        ["BUSINESS STUDIES", "Score below personal best performance, appears did not adequately prepare for test - is urged to stay focused always."],
        ["GEOGRAPHY", "Reasonable score. A determined and cooperative learner."],
        ["ENGLISH", "A pleasing score. You have come up from a lower grade. Keep it up."],
        ["RELIGIOUS EDUCATION", "Score below personal best performance, appears did not adequately prepare fortest - is urged to stay focused always."]
      ]


    };
  },

  methods: {
    downloadReportCard() {
      // Create a new jsPDF instance
      var doc = new jsPDF();

// First PDF
      doc.setFontSize(12);
      doc.text("IGCSE ACADEMIC PROGRESS REPORT", 10, 10);
      doc.text("Name: Homera Tatenda", 10, 20);
      doc.text("Class Teacher: Mr. Jackson V", 10, 30);
      doc.text("Form: 4 Diamond", 10, 40);
      doc.text("Term: 2024 Term 1", 10, 50);

// Table Header
      const headers = ["Subject", "Mark %", "Grade", "Effort", "Class Average"];
      const tableData = [
        ["Science", "", "", "", ""],
        ["Mathematics", "50", "D", "CE", "66"],
        ["Combined Science", "45", "E", "UE", "45"],
        ["Commercials", "", "", "", ""],
        ["Business Studies", "50", "D", "UE", "66"],
        ["Arts", "", "", "", ""],
        ["Geography", "26", "G", "UE", "51"],
        ["English", "32", "F", "UE", "60"],
        ["Religious Education", "30", "F", "", "63"]
      ];

      const tableTop = 70;
      const colWidth = 40;
      const rowHeight = 10;
      const cellPadding = 2;

// Draw table header
      doc.setFontSize(10);
      for (let i = 0; i < headers.length; i++) {
        doc.rect(10 + i * colWidth, tableTop, colWidth, rowHeight); // Draw cell border
        doc.text(headers[i], 10 + i * colWidth + cellPadding, tableTop + cellPadding); // Fill header text
      }

// Draw table data
      for (let row = 0; row < tableData.length; row++) {
        for (let col = 0; col < tableData[row].length; col++) {
          doc.rect(10 + col * colWidth, tableTop + (row + 1) * rowHeight, colWidth, rowHeight); // Draw cell border
          doc.text(tableData[row][col], 10 + col * colWidth + cellPadding, tableTop + (row + 1) * rowHeight + cellPadding); // Fill cell text
        }
      }

// Boarding Comment
      doc.setFontSize(10);
      doc.text("Boarding Comment:", 10, tableTop + (tableData.length + 1) * rowHeight + 10);
      doc.text("A pleasant resident who has managed to integrate well with others.", 10, tableTop + (tableData.length + 1) * rowHeight + 20);

// Rector's Comment
      doc.text("Rector's Comment:", 10, tableTop + (tableData.length + 1) * rowHeight + 30);
      doc.text("A pleasing work in progress status. Is committed and focused on her work. We expect to see further improvements.", 10, tableTop + (tableData.length + 1) * rowHeight + 40);

// Second PDF
      doc.addPage();
      doc.setFontSize(12);
      doc.text("IGCSE ASSESSMENT COMMENTS", 10, 10);

// Assessment comments table
      const assessmentHeader = ["Subject", "Teacher Comments"];
      const assessmentData = [
        ["MATHEMATICS", "Unhappy score. Numerical comprehension is particularly low. Many rehearsals needed for catch up."],
        ["BIOLOGY", "Sad score. Reading comprehension is very low. Has to read more novels."],
        ["COMBINED SCIENCE", "Avoidable poor score. This work has to be redone. Show teacher every step of the way."],
        ["BUSINESS STUDIES", "Score below personal best performance, appears did not adequately prepare for test - is urged to stay focused always."],
        ["GEOGRAPHY", "Reasonable score. A determined and cooperative learner."],
        ["ENGLISH", "A pleasing score. You have come up from a lower grade. Keep it up."],
        ["RELIGIOUS EDUCATION", "Score below personal best performance, appears did not adequately prepare for test - is urged to stay focused always."]
      ];

// Set table options
      var options = {
        startY: 20 // Set the starting Y position for the table
      };

// Add the assessment comments table to the PDF document
      doc.table(assessmentHeader, assessmentData, options);

// Save the combined PDF document
      doc.save('combined.pdf');
    }
  }
};
</script>