<template>
  <div class="our-curriculum">
    <div class="container">
      <h2>Our Comprehensive Curriculum</h2>
      <div class="row">
        <div class="col-md-4">
          <div class="curriculum-highlights">
            <h3>Curriculum Highlights</h3>
            <div class="box">
              <ul>
                <li v-for="highlight in curriculumHighlights.slice(0, 3)" :key="highlight.id">
                  <i class="fas fa-check-circle"></i>
                  {{ highlight.title }}

                </li>
              </ul>
            </div>
            <div class="box">
              <ul>
                <li v-for="highlight in curriculumHighlights.slice(3, 6)" :key="highlight.id">
                  <i class="fas fa-check-circle"></i>
                  {{ highlight.title }}
                </li>
              </ul>
            </div>
            <div class="box">
              <ul>
                <li v-for="highlight in curriculumHighlights.slice(6)" :key="highlight.id">
                  <i class="fas fa-check-circle"></i>
                  {{ highlight.title }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="course-offerings">
            <h3>Course Offerings</h3>
            <div class="box">
              <div class="course-card" v-for="course in courseOfferings.slice(0, 3)" :key="course.id">
                <i :class="`fas fa-${course.icon}`"></i>
                <h4>{{ course.name }}</h4>
                <p>{{ course.description }}</p>
              </div>
            </div>
            <div class="box">
              <div class="course-card" v-for="course in courseOfferings.slice(3, 6)" :key="course.id">
                <i :class="`fas fa-${course.icon}`"></i>
                <h4>{{ course.name }}</h4>
                <p>{{ course.description }}</p>
              </div>
            </div>
            <div class="box">
              <div class="course-card" v-for="course in courseOfferings.slice(6)" :key="course.id">
                <i :class="`fas fa-${course.icon}`"></i>
                <h4>{{ course.name }}</h4>
                <p>{{ course.description }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="extracurricular-activities">
            <h3>Extracurricular Activities</h3>
            <div class="box">
              <div class="activity-card" v-for="activity in extracurricularActivities.slice(0, 3)" :key="activity.id">
                <i :class="`fas fa-${activity.icon}`"></i>
                <h4>{{ activity.name }}</h4>
                <p>{{ activity.description }}</p>
              </div>
            </div>
            <div class="box">
              <div class="activity-card" v-for="activity in extracurricularActivities.slice(3, 6)" :key="activity.id">
                <i :class="`fas fa-${activity.icon}`"></i>
                <h4>{{ activity.name }}</h4>
                <p>{{ activity.description }}</p>
              </div>
            </div>
            <div class="box">
              <div class="activity-card" v-for="activity in extracurricularActivities.slice(6)" :key="activity.id">
                <i :class="`fas fa-${activity.icon}`"></i>
                <h4>{{ activity.name }}</h4>
                <p>{{ activity.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <EventsCalender />
        </div>
      </div>
      <div class="learn-more">
        <a href="/curriculum" class="btn">Learn More About Our Curriculum</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OurCurriculum',
  data() {
    return {
      curriculumHighlights: [
        { id: 1, title: 'Interdisciplinary Learning', icon: 'sitemap' },
        { id: 2, title: 'Project-based Assignments', icon: 'project-diagram' },
        { id: 3, title: 'STEM Focus', icon: 'microscope' },
        { id: 4, title: 'Personalized Learning', icon: 'user-graduate' },
        { id: 5, title: 'Technology Integration', icon: 'desktop' },
        { id: 6, title: 'Emphasis on Critical Thinking', icon: 'brain' },
      ],
      courseOfferings: [
        { id: 1, name: 'English', icon: 'book', description: 'Develop strong reading, writing, and communication skills.' },
        { id: 2, name: 'Mathematics', icon: 'calculator', description: 'Explore advanced mathematical concepts and applications.' },
        { id: 3, name: 'Science', icon: 'flask', description: 'Engage in hands-on experiments and laboratory investigations.' },
        { id: 4, name: 'History', icon: 'landmark', description: 'Gain a deeper understanding of past civilizations and events.' },
        { id: 5, name: 'Foreign Languages', icon: 'globe', description: 'Become proficient in multiple languages and cultures.' },
        { id: 6, name: 'Arts', icon: 'palette', description: 'Discover and cultivate your artistic talents and creativity.' },
        { id: 7, name: 'Electives', icon: 'list-ul', description: 'Customize your learning experience with a wide range of elective courses.' },
      ],
      extracurricularActivities: [
        { id: 1, name: 'Student Council', icon: 'users', description: 'Develop leadership skills and contribute to school decision-making.' },
        { id: 2, name: 'STEM Club', icon: 'microscope', description: 'Explore and collaborate on innovative science and technology projects.' },
        { id: 3, name: 'Drama Club', icon: 'theater-masks', description: 'Showcase your acting talents and put on exciting theatrical productions.' },
        { id: 4, name: 'Sports Teams', icon: 'football-ball', description: 'Participate in a variety of competitive and recreational sports.' },
        { id: 5, name: 'Debate Club', icon: 'comments', description: 'Hone your public speaking and critical thinking skills.' },
        { id: 6, name: 'Community Service Club', icon: 'hands-helping', description: 'Make a positive impact on the local community through volunteer work.' },
      ],
    };
  },
};
</script>

<style scoped>
.our-curriculum {
  background-color: #f8f8f8;
  padding: 60px 0;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}

h2, h3 {
  text-align: center;
  margin-bottom: 40px;
}

.row {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.curriculum-highlights, .course-offerings, .extracurricular-activities {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

li i {
  color: #007bff;
  margin-right: 10px;
}

.course-card, .activity-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.course-card i, .activity-card i {
  font-size: 24px;
  color: #007bff;
  margin-bottom: 10px;
}

.course-card h4, .activity-card h4 {
  margin-bottom: 10px;
}

.course-card p, .activity-card p {
  font-size: 14px;
  color: #666;
}

.learn-more {
  text-align: center;
  margin-top: 40px;
}

.btn {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #0056b3;
}
</style>