<template>
  <div class="alert-container" v-if="show">
    <div v-if="name==='error'">
      <div id="myModal" class="modal fade">
        <div class="modal-dialog modal-confirm">
          <div class="modal-content">
            <div class="modal-header">
              <div class="icon-box">
                <i class="material-icons">&#xE5CD;</i>
              </div>
              <h4 class="modal-title">Sorry!</h4>
            </div>
            <div class="modal-body">
              <p class="text-center">Your transaction has failed. Please go back and try again.</p>
            </div>
            <div class="modal-footer">
              <button class="btn btn-danger btn-block" data-dismiss="modal">OK</button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="alert " :class="name">
      <div class="row d-flex flex-column justify-content-center align-content-center">
        <div class="col-md-12 center-align">
          <p>{{ message }}</p>
          </div>
        <div class="col-md-12 center-align  mt-5">
          <p class="btn btn-secondary" @click="closeAlert">Close</p>
        </div>
        <div class="text-center">
          <!-- Button to trigger the modal -->

          <!-- Modal -->
          <div class="modal fade" :class="{ 'in': showModal }" v-if="show">
            <div class="modal-dialog modal-confirm">
              <div class="modal-content">
                <div class="modal-header">
                  <div class="icon-box">
                    <i class="material-icons">&#xE5CD;</i>
                  </div>
                  <h4 class="modal-title">Sorry!</h4>
                </div>
                <div class="modal-body">
                  <p class="text-center">Your transaction has failed. Please go back and try again.</p>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-danger btn-block" @click="closeModal">OK</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  props: {
    name,

    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: true,
      showModal: false
    };
  },
  methods: {
    closeAlert() {
      this.show = false;
      if(this.name==='success'){
        this.$emit("clear")
      }


    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  },
};
</script>

<style scoped>
.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.alert {
  width: 350px;
  padding: 20px;
  border-radius: 4px;

  background: #2c3e50;
height: 150px;
}




.success {
  background-color: green;
  color:whitesmoke;
}



.error {
  background-color:crimson;
  color: whitesmoke;
}



      body {
        font-family: 'Varela Round', sans-serif;
      }
      .modal-confirm {
        color: #636363;
        width: 325px;
        margin: 80px auto 0;
      }
      .modal-confirm .modal-content {
        padding: 20px;
        border-radius: 5px;
        border: none;
      }
      .modal-confirm .modal-header {
        border-bottom: none;
        position: relative;
      }
      .modal-confirm h4 {
        text-align: center;
        font-size: 26px;
        margin: 30px 0 -15px;
      }
      .modal-confirm .form-control, .modal-confirm .btn {
        min-height: 40px;
        border-radius: 3px;
      }
      .modal-confirm .close {
        position: absolute;
        top: -5px;
        right: -5px;
      }
      .modal-confirm .modal-footer {
        border: none;
        text-align: center;
        border-radius: 5px;
        font-size: 13px;
      }
      .modal-confirm .icon-box {
        color: #fff;
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: -70px;
        width: 95px;
        height: 95px;
        border-radius: 50%;
        z-index: 9;
        background: #ef513a;
        padding: 15px;
        text-align: center;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
      }
      .modal-confirm .icon-box i {
        font-size: 56px;
        position: relative;
        top: 4px;
      }
      .modal-confirm .btn {
        color: #fff;
        border-radius: 4px;
        background: #ef513a;
        text-decoration: none;
        transition: all 0.4s;
        line-height: normal;
        border: none;
      }
      .modal-confirm .btn:hover, .modal-confirm .btn:focus {
        background: #da2c12;
        outline: none;
      }
      .trigger-btn {
        display: inline-block;
        margin: 100px auto;
      }


        </style>