import axios from 'axios'
export class services  {
    static ServerURL=`http://localhost:8080/http://localhost:8084/api/teacher`


//student
    static saveStudent(data) {
        let dataURL = `${this.ServerURL}/student`
        return axios.post(dataURL, data)
    }
    static  getStudent (studentId){
        let dataURL=`${this.ServerURL}/student/${studentId}`
        return axios.get(dataURL)

    }
    static  getStudentByStudentNumber (studentNumber){
        let dataURL=`${this.ServerURL}/student/${studentNumber}`
        return axios.get(dataURL)

    }
    static  getAllStudents (){
        let dataURL=`${this.ServerURL}/student`
        return axios.get(dataURL)

    }
    static  deleteStudent (studentId){
        let dataURL=`${this.ServerURL}/student/${studentId}`
        return axios.delete(dataURL,studentId)

    }
    static  updateStudent (event,studentId){
        let dataURL=`${this.ServerURL}/student/${studentId}`
        return axios.put(dataURL,event)

    }
    //Contactus
    static saveMessage(data){
        let dataURL=`${this.ServerURL}/contact_us`
        return axios.post(dataURL,data)
    }
    static  getAllMessages (){
        let dataURL=`${this.ServerURL}/contact_us`
        return axios.get(dataURL)

    }
    //Newsletter
    static saveSubscriber(data){
        let dataURL=`${this.ServerURL}/newsletter/subscriber`
        return axios.post(dataURL,data)
    }
    static  getAllNewsletter (){
        let dataURL=`${this.ServerURL}/newsletter/subscriber`
        return axios.get(dataURL)

    }

    //Event

    static  saveEvent (data){
        let dataURL=`${this.ServerURL}/event`
        return axios.post(dataURL,data)

    }

    static  getAllEvents (){
        let dataURL=`${this.ServerURL}/event`
        return axios.get(dataURL)

    }

    static  getEvent (eventId){
        let dataURL=`${this.ServerURL}/event/${eventId}`
        return axios.get(dataURL,eventId)

    }
    static  updateEvent (event,eventId){
        let dataURL=`${this.ServerURL}/event/${eventId}`
        return axios.put(dataURL,event)

    }
    //end of Event

//student
    static saveResult(data) {
        let dataURL = `${this.ServerURL}/student/results`
        return axios.post(dataURL, data)
    }
    static updateResult (studentId){
        let dataURL=`${this.ServerURL}/student/results/${studentId}`
        return axios.get(dataURL)

    }
    static  getResultsByStudentNumber (studentNumber){
        let dataURL=`${this.ServerURL}/student/results/${studentNumber}`
        return axios.get(dataURL)

    }
    static  getAllResults (){
        let dataURL=`${this.ServerURL}/student/results`
        return axios.get(dataURL)

    }
    static  deleteEvent (studentId){
        let dataURL=`${this.ServerURL}/student/results/${studentId}`
        return axios.delete(dataURL,studenResult)}

//Teachers
    static  getAllTeachers (){
        let dataURL=`${this.ServerURL}/teacher`
        return axios.get(dataURL)

    }

    static saveTeacher(data) {
        let dataURL = `${this.ServerURL}/teacher`
        return axios.post(dataURL, data)
    }
}