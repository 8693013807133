import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import AdmissionForm from"../views/AdmissionForm.vue";
import ContactUs from "../views/ContactUs.vue";
import History from "../views/History.vue";
import Teachers from "../views/Teachers.vue";
import feesStructure from "../views/feesStructure.vue";
import howToApply from "../views/howToApply.vue";
import SubjectsAndCourses from "../views/SubjectsAndCourses.vue";
import PrincipalMessage from "../views/PrincipalMessage.vue";
import StaffLogin from "../views/StaffLogin.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import StudentLogin from "../views/Studentlogin.vue";
import registrationView from "../views/registrationView.vue";
import StudentDashboard  from "../views/StudentDashboard";
import payments  from "../views/payments";
import acceptpayment  from "../views/acceptpayment.vue";
import admissionFormCont from "../views/admissionFormCont.vue"
import Parents from "@/views/Parents/Parents.vue";
import ParentsDashboard from "@/views/Parents/ParentsDashboard.vue";
import Results from "@/views/Parents/Results.vue";
// import DashBoard from "@/views/theAdmin/DashBoard.vue";
// import AdminDashboard from "@/views/theAdmin/AdminDashboard.vue";
// import AllStudents from "@/views/theAdmin/Students/AllStudents.vue";
// import AddStudent from "@/views/theAdmin/Students/AddStudent.vue";
// import BlogDetails from "@/views/theAdmin/Blog/BlogDetails.vue";
// import EditBlog from "@/views/theAdmin/Blog/EditBlog.vue";
// import AddBlog from "@/views/theAdmin/Blog/AddBlog.vue";
// import Blog from "@/views/theAdmin/Blog/Blog.vue";
import PageNotFound from "@/components/PageNotFound.vue";
// import EditStudent from "@/views/theAdmin/Students/EditStudent.vue";
// import AboutStudent from "@/views/theAdmin/Students/AboutStudent.vue";
// import AddResult from "@/views/theAdmin/Students/Results/AddResult.vue";
import AllEvents from "@/views/AllEvents.vue";
import EventDetails from "@/views/EventDetails.vue";
import test from "@/views/test.vue";
// import AddTeacher from "@/views/theAdmin/Teachers/AddTeacher.vue";
// import notification from "@/views/theAdmin/notification.vue";
const routes = [
  {
    path:"/admissionFormCont",
    name:"admissionFormCont",
    component:admissionFormCont,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/AdmissionForm",
    name: "AdmissionForm",
    component: AdmissionForm,
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },

  {
    path: "/StudentLogin",
    name: "StudentLogin",
    component: StudentLogin,
  },
  {
    path: "/StaffLogin",
    name: "StaffLogin",
    component: StaffLogin,
  },

  {
    path: "/registration",
    name: "registrationView",
    component: registrationView,
  },
  {
    path: "/History",
    name: "History",
    component: History,
  },
  {
    path: "/Teachers",
    name: "Teachers",
    component: Teachers,
  },
  {
    path: "/feesStructure",
    name: "feesStructure",
    component: feesStructure,
  },
  {
    path: "/howToApply",
    name: "howToApply",
    component: howToApply,
  },
  {
    path: "/SubjectsAndCourses",
    name: "SubjectsAndCourses",
    component: SubjectsAndCourses,
  } ,
  {
    path: "/PrincipalMessage",
    name: "/PrincipalMessage",
    component: PrincipalMessage,
  },
  {
    path: "/PrivacyPolicy",
    name: "/PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/StudentDashboard",
    name: "/StudentDashboard",
    component: StudentDashboard,
  },
  {
    path: "/payments",
    name: "/payments",
    component: payments,
  },
  {
    path: "/acceptpayment",
    name: "acceptpayment",
    component: acceptpayment,
  },
    /*parents routes*/
  {
    path: "/Parents",
    name: "ParentsLogin",
    component: Parents,
  },
  {
    path: "/ParentsDashboard",
    name: "ParentsDashboard",
    component: ParentsDashboard,
  },

  {
    path: "/Results",
    name: "Results",
    component: Results,
  },
  {
    path: "/AllEvents",
    name: "AllEvents",
    component: AllEvents,
  },{
    path: "/test",
    name: "test",
    component: test,
  },
  {
    path: "/EventDetails/:eventId",
    name: "Events",
    component: EventDetails,
  },
  // {
  //   path: "/Dashboard",
  //   name: "Dashboard",
  //   component:DashBoard ,
  //   children:[
  //     {
  //       path:'/AdminDashboard',
  //       name:"AdminDashboard",
  //       component:AdminDashboard
  //     },
  //     {
  //       path:'/notifications',
  //       name:"notifications",
  //       component:notification
  //     },
  //       //students
  //     {
  //       path:'/Allstudents',
  //       name:"students",
  //       component:AllStudents
  //     },
  //     {
  //       path:'/AddStudent',
  //       name:"AddStudent",
  //       component:AddStudent
  //     },
  //     {
  //       path:'/AboutStudent/:studentId',
  //       name:"AboutStudent",
  //       component:AboutStudent
  //     },
  //     {
  //       path:'/EditStudent/:studentId',
  //       name:"EditStudent",
  //       component:EditStudent
  //     },
  //
  //     {
  //       path:'/DeleteStudent/:eventId',
  //       name:"DeleteStudent",
  //       component:EditBlog
  //     },
  //       //student results
  //     {
  //       path:'/AddResult',
  //       name:"AddResult",
  //       component:AddResult
  //     },
  //     //End of students results
  //       //End of students
  //       //Blog Start
  //     {
  //       path:'/Blog',
  //       name:"Blog",
  //       component:Blog
  //     },
  //     {
  //       path:'/AddBlog',
  //       name:"AddBlog",
  //       component:AddBlog
  //     },
  //     {
  //       path:'/BlogDetails',
  //       name:"BlogDetails",
  //       component:BlogDetails
  //     },
  //     {
  //       path:'/EditBlog/:eventId',
  //       name:"EditBlog",
  //       component:EditBlog
  //     },
  //       //Teachers
  //     {
  //       path:'/AddTeacher',
  //       name:"AddTeacher",
  //       component:AddTeacher
  //     },
  //
  //     {
  //       path:'/:catchAll(.*)',
  //       name:"NotFound",
  //       component:PageNotFound
  //     },
  //  ]
  // },
  {
    path:'/:catchAll(.*)',
    name:"NotFound",
    component:PageNotFound
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
